import request from "superagent";
import { authProvider } from "../../../../authProvider";
import { baseURL } from "../../../../services/constants";
import { getAccessToken } from "../../../../services/commonFunctions";

let accounts;
// let loginInfo = false;

export const MANAGEMENT_WITH_DRIVERS_NAV_CREATED = "MANAGEMENT_WITH_DRIVERS_NAV_CREATED";

const managementWithDriversNavCreated = (driverData, dispatch, isMentor, group, isC2cLoginInfoRequested) => ({
  type: MANAGEMENT_WITH_DRIVERS_NAV_CREATED,
  payload: {
    driverData: driverData,
    dispatch: dispatch,
    isMentor: isMentor,
    group: group,
    isC2cLoginInfoRequested: isC2cLoginInfoRequested
  },
});

export const getDriverList = (companyId, companyName, groupsData) => async (dispatch, getState) => {
  const store = getState();
  var { auth, roles } = store;
  let token = auth?.accessToken;
  let isMentorLoggedIn = true; //no mentor in new portal
  let isC2cLoginInfoRequested = false;

  //check for showing login status in coach2coach portal. Roles returns "c2clogininfo" for the c2c accounts who wants to see the login status of drivers
  if (isMentorLoggedIn) {
    isC2cLoginInfoRequested = roles?.find((role) => role.roleString === "c2clogininfo") ? true : false;
    // loginInfo = true;
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/management/Drivers/GetDriversList?companyId=${companyId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res && res.unauthorized) {
        console.log(`401 error, retry from drivers API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    }).then((response) => {
      //   dispatch(driversFetched(response.body));
      if (response.body.length === 0) {
        dispatch(managementWithDriversNavCreated([{ companyId: companyName }], dispatch, false, null, false));
      } else if (groupsData.length === 2) {
        let groupName = groupsData?.find((group) => group.parentGroupId !== null)?.name
        dispatch(
          managementWithDriversNavCreated(response.body, dispatch, isMentorLoggedIn, groupName, isC2cLoginInfoRequested)
        );
      }
      else if (groupsData.length > 2) {
        groupsData.map(group => {
          let filteredDrivers = response.body.filter(driver =>
            driver.companyGroup.name ? driver.companyGroup.name.toLowerCase() === group.name.toLowerCase() : null
          )
          if (group.parentGroupId === null) {
            dispatch(managementWithDriversNavCreated([{ companyId: `${companyName} Overview` }], dispatch, false, null, false));
          }
          else {
            if (filteredDrivers.length > 0) {
              dispatch(
                managementWithDriversNavCreated(filteredDrivers, dispatch, isMentorLoggedIn, group.name, isC2cLoginInfoRequested)
              );
            }
          }

        })
      } else {
        dispatch(
          managementWithDriversNavCreated(response.body, dispatch, isMentorLoggedIn, null, isC2cLoginInfoRequested)
        );
      }
    }
    )
    .catch(console.error);

}

// filtering the coaching navigation for driver search functionality
export const MNG_NAV_FILTERED = "MNG_NAV_FILTERED";

// export const FILTERED_OUTPUT = "FILTERED_OUTPUT";

export const filterNavOptions = (keyword) => ({
  type: MNG_NAV_FILTERED,
  payload: keyword,
});

// export const filteredOutput = (value) => ({
//   type: FILTERED_OUTPUT,
//   payload: value,
// });

export const undoSearch = () => (dispatch, getState) => {
  const store = getState();
  let { mngWithDriversNaviState } = store;
  dispatch(coachNavUndo(dispatch, mngWithDriversNaviState));
};

//  resetting the coaching navigation without refetching
export const MNG_NAV_RESETTED = "MNG_NAV_RESETTED";

export const coachNavUndo = (dispatch, currentState) => ({
  type: MNG_NAV_RESETTED,
  payload: {
    dispatch: dispatch,
    currentState,
  },
});
