//import React from 'react'
import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";

import { getAccessToken } from "../../../services/commonFunctions";
//import Papa from "papaparse";
import i18next from "i18next";

export const DRIVERS_REQUESTED_PRIZES_TABLE_FETCHED =
  "DRIVERS_REQUESTED_PRIZES_TABLE_FETCHED";
//export const DRIVERS_REQUESTED_PRIZES_UPDATED = "DRIVERS_REQUESTED_PRIZES_UPDATED";

let accounts;

const loadDriversRequestedPrizesTableFetch = (drivers) => ({
  type: DRIVERS_REQUESTED_PRIZES_TABLE_FETCHED,
  payload: drivers,
});

// drivers requested prizes table is loading

export const DRIVERS_REQUESTED_PRIZES_TABLE_LOADING_ON =
  "DRIVERS_REQUESTED_PRIZES_TABLE_LOADING_ON";

const DriversRequestedprizesTableIsLoading = () => ({
  type: DRIVERS_REQUESTED_PRIZES_TABLE_LOADING_ON,
});

// prizes table is not loading anymore
export const DRIVERS_REQUESTED_PRIZES_TABLE_LOADING_OFF =
  "DRIVERS_REQUESTED_PRIZES_TABLE_LOADING_OFF";

const DriversRequestedprizesTableIsNotLoading = () => ({
  type: DRIVERS_REQUESTED_PRIZES_TABLE_LOADING_OFF,
});

export const loadDriversRequestedPrizesTable =
  (companyId) => async (dispatch, getState) => {
    // console.log(companyId, getState);
    // const dummyDriverPrizesRequested = [
    //   {
    //     driver: {
    //       driverId: "test0001",
    //       personalDetails: {
    //         givenName: "Jan",
    //         surname: "De Groot",
    //       },
    //     },
    //     prizeTransaction: {
    //       prizeTransactionId: "trans_id_0001",
    //       dateRequested: "21/06/2022",
    //       dateReceived: "27/06/2022",
    //       received: true,
    //     },
    //     id: "1",
    //     prizesId: "prizeId0001",
    //   },
    //   {
    //     driver: {
    //       driverId: "test0002",
    //       personalDetails: {
    //         givenName: "Sjonnie",
    //         surname: "Adel",
    //       },
    //     },
    //     prizeTransaction: {
    //       prizeTransactionId: "trans_id_0002",
    //       dateRequested: "21/06/2022",
    //       dateReceived: "27/06/2022",
    //       received: false,
    //     },
    //     id: "2",
    //     prizesId: "prizeId0002",
    //   },
    // ]; // will come from API
    // await dispatch(
    //   loadDriversRequestedPrizesTableFetch(dummyDriverPrizesRequested)
    // );

    ///   Once the API is ready the above /\ can be replaced with the below \/

    // //********************* with API:

    const store = getState();
    let { auth } = store;
    // const { driversRequestedPrizesTable } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(
        `${baseURL}/portal/management/Prizes/GetPrizesTransactions/?companyId=${companyId}&take=150`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from a prizes table API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        // if (
        //   driversRequestedPrizesTable &&
        //   driversRequestedPrizesTable.length > 0
        // ) {
        //   //prizesTable
        //   if (driversRequestedPrizesTable.length === response.body.length) {
        //     return;
        //   }
        // }
        // console.log("inside API call disatch")
        dispatch(loadDriversRequestedPrizesTableFetch(response.body));
      })
      .catch((e) => {
        console.log(e.response);
        alert(
          i18next.t("translation:ErrorMsg.15", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
          })
        );
      });
  };

export const PRIZE_RECEIVED = "PRIZE_RECEIVED";

const prizeReceived = (transactionId) => ({
  type: PRIZE_RECEIVED,
  payload: transactionId,
});

export const receivedPrize = (transactionId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(DriversRequestedprizesTableIsLoading());

  request
    .post(
      `${baseURL}/portal/management/Prizes/SetTransactionComplete?transactionId=${transactionId}`
    )
    .set("Content-Type", "application/json")
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a prizes table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(DriversRequestedprizesTableIsNotLoading());

      if (response.ok) {
        dispatch(prizeReceived(transactionId));
      } else {
        alert(i18next.t("translation:ErrorMsg.17"), response.statusType);
      }
      // response format:
      //   {
      //     "statusCode": 200,
      //     "message": "User deleted",
      //     "exception": null,
      //     "user": null
      // }
    })
    .catch((e) => {
      //dispatch(prizesTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.16", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

//Indication that prize has been handed out to the driver was canceled - will remove set a receive date
export const PRIZE_RECEIVED_CANCELED = "PRIZE_RECEIVED_CANCELED";

const prizeReceivedCanceled = (transactionId) => ({
  type: PRIZE_RECEIVED_CANCELED,
  payload: transactionId,
});

export const receivedPrizeCanceled =
  (transactionId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    //  dispatch(DriversRequestedprizesTableIsLoading());

    request
      .post(
        `${baseURL}/portal/management/Prizes/CancelRequest?transactionId=${transactionId}`
      )
      .set("Content-Type", "application/json") // throws error 415 (unsupported media type) and complains that the form is not connected, the json type throws 400 bad request as it's not a json
      //.send("{\r\n\"transactionId\":\"" + transactionId + "\"\r\n}")
      //.send("{\"PrizesId\":\"22ccffa3-f584-434a-ae25-15d68adb606d\"}")
      //.send("{\"PrizesId\":\"22ccffa3-f584-434a-ae25-15d68adb606d\"}")
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from a prizes table API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        //dispatch(DriversRequestedprizesTableIsNotLoading());

        if (response.ok) {
          dispatch(prizeReceivedCanceled(transactionId));
        } else {
          alert(i18next.t("translation:ErrorMsg.17"), response.statusType);
        }
        // response format:
        //   {
        //     "statusCode": 200,
        //     "message": "User deleted",
        //     "exception": null,
        //     "user": null
        // }
      })
      .catch((e) => {
        //dispatch(prizesTableIsNotLoading());
        console.log(e.response);
        alert(
          i18next.t("translation:ErrorMsg.16", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
          })
        );
      });
  };
