import { createStore, applyMiddleware, compose } from "redux";
import reducer from "./commonReducers/index";
import ReduxThunk from "redux-thunk";

export const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : (x) => x;

export const middlewares = [ReduxThunk];

const enhancer = compose(applyMiddleware(...middlewares), devTools);

const store = createStore(reducer, enhancer);

export default store;
