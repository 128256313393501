import { DRIVER_LOADING_TRUE_MNG, DRIVER_LOADING_FALSE_MNG } from "../actions/driverData";

export default (state = false, action = {}) => {
  switch (action.type) {
    case DRIVER_LOADING_TRUE_MNG:
      return true;
    case DRIVER_LOADING_FALSE_MNG:
      return false;
    default:
      return state;
  }
};
