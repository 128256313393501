import { DRIVER_RANK_CREATED, DRIVER_RANK_RESETTED } from "../actions/driverRank";

export default (state = [], action = {}) => {
  switch (action.type) {
    case DRIVER_RANK_CREATED:
      if (action.payload !== null) { // what is the response if there's no rank??
        return action.payload;
      } else {
        return [];
      }
    case DRIVER_RANK_RESETTED:
      return null;
    default:
      return state;
  }
};
