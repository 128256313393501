import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { loadCompanyGroups } from "./companyGroups";
import i18n from "../../../i18n";
import { getAccessToken } from "../../../services/commonFunctions";
import { getFeaturesAvailable } from "./getFeaturesAvailable";
import { loadDriversTable } from "./driversTable";
let accounts;
// fetching the visualization URLs
export const VIS_URLS_FETCHED = "VIS_URLS_FETCHED";
const visUrlsFetched = (visUrls) => ({
  type: VIS_URLS_FETCHED,
  payload: visUrls,
});
export const loadCompany = (companyId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let { visUrls } = store;
  let token = auth?.accessToken;
  if (visUrls) {
    if (visUrls.companyId === companyId) {
      return;
    }
  }
  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  request
    .get(`${baseURL}/portal/management/companies/getcompany/${companyId}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from visURLs API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      // different features for Zebra company
      // console.log("visualizations",response)
  
      dispatch(visUrlsFetched(response.body));
      if (response.body.companyName) {
        dispatch(
          createManagementNavi({
            companyName: response.body.companyName,
            companyId: companyId,
          })
        );
      } else {
        dispatch(
          createManagementNavi({
            companyName: null,
            companyId: companyId,
          })
        );
      }
    })
    .catch(console.error);
};
// creating empty management navigation
export const EMPTY_MNG_NAV_CREATED = "EMPTY_MNG_NAV_CREATED";
const emptyMngNavCreated = (mngNavigation) => ({
  type: EMPTY_MNG_NAV_CREATED,
  payload: mngNavigation,
});
const createManagementNavi = (companyData) => (dispatch) => {
  const customerName = companyData.companyName;
  const customerId = companyData.companyId;
  if (customerId) {
    //loading companyGroups
    dispatch(loadCompanyGroups(customerId, customerName));

    //load company drivers
    dispatch(loadDriversTable(customerId));

    //loading the feature table 
    dispatch(getFeaturesAvailable());
  } else {
    const result = {
      items: [
        {
          title: true,
          name: "Management",
        },
        {
          name: i18n.t("translation:Company.6"),
          // name: customerName,
          url: customerName ? `/management/${customerName}` : "/management",
          icon: "icon-graph",
        },
      ],
    };
    dispatch(emptyMngNavCreated(result));
  }
};



