//import React from 'react'
import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
import i18next from "i18next";
import { BEHAVIOR_VS_MONTHS_DATA_FETCHED, RESET_BEHAVIOR_VS_MONTHS, RESTORE_DRIVER_VS_SCORES_STATE, CLEAR_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE, APPLY_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE, APPLY_FILTER_ON_NEW_DRIVER_VS_SCORES_STATE, APPLY_FILTER_ON_CURRENT_DRIVER_VS_SCORES_STATE, TOGGLE_LOADING, DRIVER_VS_MONTHS_DATA_FETCHED, RESET_DRIVER_VS_MONTHS, APPLY_SEARCH_ON_DRIVER_VS_MONTHS, CLEAR_SEARCH_ON_DRIVER_VS_MONTHS, DRIVER_VS_SCORES_DATA_FETCHED, RESET_DRIVER_VS_SCORES } from "../../Management/actions/scoreOverview";

// export const BEHAVIOR_VS_MONTHS_DATA_FETCHED = "BEHAVIOR_VS_MONTHS_DATA_FETCHED";
// export const RESET_BEHAVIOR_VS_MONTHS = "RESET_BEHAVIOR_VS_MONTHS";
// export const TOGGLE_LOADING = "TOGGLE_LOADING";

let accounts;

const behaviorsVsMonthsData = ({ date, data }) => ({
  type: BEHAVIOR_VS_MONTHS_DATA_FETCHED,
  payload: { date, data }
})

const clearBehaviorsVsMonthsData = () => ({
  type: RESET_BEHAVIOR_VS_MONTHS,
})

const toggleLoading = () => ({
  type: TOGGLE_LOADING,
})

// export const DRIVER_VS_MONTHS_DATA_FETCHED = "DRIVER_VS_MONTHS_DATA_FETCHED";
// export const RESET_DRIVER_VS_MONTHS = "RESET_DRIVER_VS_MONTHS";
// export const APPLY_SEARCH_ON_DRIVER_VS_MONTHS = "APPLY_SEARCH_ON_DRIVER_VS_MONTHS";
// export const CLEAR_SEARCH_ON_DRIVER_VS_MONTHS = "CLEAR_SEARCH_ON_DRIVER_VS_MONTHS";

const driverVsMonthsData = ({ date, data }) => ({
  type: DRIVER_VS_MONTHS_DATA_FETCHED,
  payload: { date, data }
})

export const applySearchOnDriverVsMonthsDataCoaching = (driver) => ({
  type: APPLY_SEARCH_ON_DRIVER_VS_MONTHS ,
  payload:driver
})

export const clearSearchOnDriverVsMonthsDataCoaching = () => ({
  type: CLEAR_SEARCH_ON_DRIVER_VS_MONTHS ,
})

const clearDriverVsMonthsData = () => ({
  type: RESET_DRIVER_VS_MONTHS,
})

// export const DRIVER_VS_SCORES_DATA_FETCHED = "DRIVER_VS_SCORES_DATA_FETCHED";
// export const RESET_DRIVER_VS_SCORES = "RESET_DRIVER_VS_SCORES";
// export const APPLY_FILTER_ON_CURRENT_DRIVER_VS_SCORES_STATE = "APPLY_FILTER_ON_CURRENT_DRIVER_VS_SCORES_STATE";
// export const APPLY_FILTER_ON_NEW_DRIVER_VS_SCORES_STATE = "APPLY_FILTER_ON_NEW_DRIVER_VS_SCORES_STATE";
// export const APPLY_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE = "APPLY_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE";
// export const CLEAR_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE = "CLEAR_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE"
// export const RESTORE_DRIVER_VS_SCORES_STATE = "RESTORE_DRIVER_VS_SCORES_STATE";

const driverVsScoresDataFetched = ({ date, data }) => ({
  type: DRIVER_VS_SCORES_DATA_FETCHED,
  payload: { date, data }
})

const clearDriverVsScoresData = () => ({
  type: RESET_DRIVER_VS_SCORES,
})

export const applySearchOnDriverVsScoresDataCoaching = (driver) => ({
  type: APPLY_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE,
  payload:driver
})

export const clearSearchOnDriverVsScoresDataCoaching = () => ({
  type: CLEAR_SEARCH_ON_NEW_DRIVER_VS_SCORES_STATE,
})

export const restoreDriverVsScoresDataCoaching = () => ({
  type: RESTORE_DRIVER_VS_SCORES_STATE,
})

const applyFilterWithoutFetchingNewData = (filters) => ({
  type: APPLY_FILTER_ON_CURRENT_DRIVER_VS_SCORES_STATE,
  payload: filters
})

const applyFilterAfterFetchingNewData = ({ date, data, filters }) => ({
  type: APPLY_FILTER_ON_NEW_DRIVER_VS_SCORES_STATE,
  payload: { date, data, filters }
})

export const getBehaviorsVersusMonthsCoaching = ({ date, body }) => async (dispatch, getState) => {
  const store = getState();
  let { auth, behaviorsVsMonths } = store;
  let token = auth?.accessToken;

  if (behaviorsVsMonths?.data?.length !== 0) {
    if (behaviorsVsMonths?.date?.startDate === date?.startDate && behaviorsVsMonths?.date?.endDate === date?.endDate) {
      return;
    }
    else {
      dispatch(clearBehaviorsVsMonthsData())
      dispatch(toggleLoading())
    }
  } else {
    dispatch(toggleLoading())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .post(
      `${baseURL}/portal/coaching/Graphs/BehaviorsVersusMonths`
    ).set("Content-Type", "application/json")
    .send(body)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(
          `401 error, retry from a get achievements of user api, err is ${err}`
        );
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {

      dispatch(behaviorsVsMonthsData({ date: date, data: response.body }));
      dispatch(toggleLoading())
    })
    .catch((e) => {
      console.log(`error in getting BehaviorsVersusMonths data: ${e.response}`);
      dispatch(toggleLoading())
      alert(
        i18next.t("translation:ErrorMsg.22", {
          // errorMsg: e.response.detail,
          // errorCode: e.response.status,
          serverError: i18next.t("translation:ErrorCat.serverError", {
            eMsg: e.message,
            eCode: e.response.statusCode,
          }),
        })
      );
    });
};

export const getDriversVersusMonthsCoaching = ({ date, body }) => async (dispatch, getState) => {
  const store = getState();
  let { auth, driverVsMonths } = store;
  let token = auth?.accessToken;

  if (driverVsMonths?.data?.length !== 0) {
    if (driverVsMonths?.date?.startDate === date?.startDate && driverVsMonths?.date?.endDate === date?.endDate && driverVsMonths?.data[0]?.data[0]?.behaviorId === body?.BehaviorId) {
      return;
    } else {
      dispatch(clearDriverVsMonthsData())
      dispatch(toggleLoading())
    }
  } else {
    dispatch(toggleLoading())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  // const body={
  //   "From": "2024-01-12",
  //   "To": "2024-05-12",
  //   "CompanyId": "443325ee-786a-41c1-9bc8-d6d446a0af75",
  //   "companyGroupId": "bedef852-8a0c-4de4-57a4-08da8a842573",
  //   "PageSize" : 10,
  //   "PageNumber" : 1
  // }

  request
    .post(
      `${baseURL}/portal/coaching/Graphs/DriversVersusMonths`
    ).set("Content-Type", "application/json")
    .send(body)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(
          `401 error, retry from a get achievements of user api, err is ${err}`
        );
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverVsMonthsData({ date: date, data: response.body }))
      dispatch(toggleLoading())
    })
    .catch((e) => {
      console.log(`error in getting getDriverVersusMonthss: ${e.response}`);
      dispatch(toggleLoading())
      alert(
        i18next.t("translation:ErrorMsg.22", {
          // errorMsg: e.response.detail,
          // errorCode: e.response.status,
          serverError: i18next.t("translation:ErrorCat.serverError", {
            eMsg: e.message,
            eCode: e.response.statusCode,
          }),
        })
      );
    });
};

export const getDriverVersusBehaviorsCoaching = ({ date, body, filters }) => async (dispatch, getState) => {
  const store = getState();
  let { auth, driverVsScores } = store;
  let token = auth?.accessToken;

  if (filters === "initialLoad") {
    if (driverVsScores?.data?.length !== 0) {
      if (driverVsScores?.date?.startDate === date?.startDate && driverVsScores?.date?.endDate === date?.endDate) {
        return;
      } else {
        dispatch(clearDriverVsScoresData());
        dispatch(toggleLoading())
      }
    } else {
      dispatch(toggleLoading())
    }
  } else {
    if (driverVsScores?.date?.startDate === date?.startDate && driverVsScores?.date?.endDate === date?.endDate) {
      if(filters[0]?.scoreType==='' ||filters[0]?.value===''||filters[0]?. operator==='' ){
        return
      }else{
        dispatch(toggleLoading())
        dispatch(applyFilterWithoutFetchingNewData(filters));
        dispatch(toggleLoading())
        return;
      }
    }
    else {
      dispatch(clearDriverVsScoresData());
      dispatch(toggleLoading())
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  request
    .post(
      `${baseURL}/portal/coaching/Graphs/DriverVersusBehaviors`
    ).set("Content-Type", "application/json")
    .send(body)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(
          `401 error, retry from a get achievements of user api, err is ${err}`
        );
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      if (filters === "initialLoad") {
        dispatch(driverVsScoresDataFetched({ date: date, data: response.body }))
      } else {
        dispatch(applyFilterAfterFetchingNewData({ date: date, data: response.body, filters: filters }))
      }
      dispatch(toggleLoading())
    })
    .catch((e) => {
      dispatch(toggleLoading())
      console.log(`error in getting getDriverVersusBehaviors: ${e.response}`);
      alert(
        i18next.t("translation:ErrorMsg.22", {
          eMsg: e.message,
          eCode: e.response.statusCode,
        })
      );
    });
};

// export const applyFiltersOnDriversVsScores=(date,filters)=>async (dispatch,getState)=>{
//   const store = getState();
//   let { auth,driverVsScores } = store;
// }

export const COMPANY_BEHAVIORS_FETCHED = "COMPANY_BEHAVIORS_FETCHED";
export const CLEAR_COMPANY_BEHAVIORS = "CLEAR_COMPANY_BEHAVIORS";

const companyBehaviorsFetched = ({data, companyId}) => (
  {
    type: COMPANY_BEHAVIORS_FETCHED,
    payload:{data, companyId}
  }
)

const clearCompanyBehaviors = () => (
    {
      type: CLEAR_COMPANY_BEHAVIORS,
    }
  )
export const getBehaviorsOfCompanyCoaching = (companyId) => async (dispatch, getState) => {
  const store = getState();
  let { auth, companyBehaviors } = store;
  let token = auth?.accessToken;

  if(companyBehaviors?.behaviors && companyBehaviors?.companyId ===companyId ){
    return;
  }else{
    dispatch(clearCompanyBehaviors())
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  request
    .get(
      `${baseURL}/portal/coaching/Behavior/GetBehaviors?companyId=${companyId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(
          `401 error, retry from a get achievements of user api, err is ${err}`
        );
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(companyBehaviorsFetched({data:response.body,companyId:companyId}))
    })
    .catch((e) => {
      console.log(`error in getting behaviours: ${e.response}`);
      alert(
        i18next.t("translation:ErrorMsg.22", {
          eMsg: e.message,
          eCode: e.response.statusCode,
        })
      );
    });
};