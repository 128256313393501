import { TOGGLE_TACHO_LOADING } from "../actions/TachoApis";

let loading = false;
export default (state = loading, action = {}) => {
    switch (action.type) {
        case TOGGLE_TACHO_LOADING:
            return !state;
      default:
    return state;
}
  };