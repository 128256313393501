import { MNG_NAV_CREATED } from "../actions/companyGroups";
import { EMPTY_MNG_NAV_CREATED } from "../actions/company";
import { LANG_CHANGE } from "../../Coaching/LanguageSwitcher";

export default (state = null, action = {}) => {
  switch (action.type) {
    case MNG_NAV_CREATED:
      return action.payload;
    case EMPTY_MNG_NAV_CREATED:
      return action.payload;
    case LANG_CHANGE:
      if (state !== null) {
        if (action.payload === "en") {
          state.items[1].name = "Progress";
          return { ...state };
        } else {
          state.items[1].name = "Voortgang";
          return { ...state };
        }
      } else {
        return null;
      }
    default:
      return state;
  }
};
