import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
import { loadDriverPrizesTable, loadManagerGroups } from "./driverPrizesTable";
import { loadAchievements } from "./achievements";
import { loadDriversRequestedPrizesTable } from "./driversRequestedPrizesTable";
import { loadDriverCredits } from "./driverCreditsTable";
import { getBehaviorsOfCompany } from "./scoreOverview";
let accounts;

export const AVAILABLE_FEATURES_FETCHED = "FEATURES_FETCHED";

const companyFeatures = (features) => ({
    type: AVAILABLE_FEATURES_FETCHED,
    payload: features
})


export const getFeaturesAvailable = () => async (dispatch, getState)=>{
    const store = getState();
    let { auth, loggedInMngtData } = store;
  let token = auth?.accessToken;
  
  // console.log(auth)

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }
    request
      .get(`${baseURL}/portal/management/features/GetFeaturesAvailable`)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from companyFeatures API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
          // console.log(response.body);
        dispatch(companyFeatures(response.body))  
        
        const rewardsEnabled = response.body?.find((item) => item.name === "credits") ? true : false;
        if (rewardsEnabled) {
          //fire reward module APIs only if the company has the feature enabled

          //groups for prizes
          dispatch(loadManagerGroups());

          //acheivements or credits amount list particular for the company
          dispatch(loadAchievements(loggedInMngtData?.companyId));

          //The prize transactions for the companies
          dispatch(loadDriversRequestedPrizesTable(loggedInMngtData?.companyId));

          //Available prizes
          dispatch(loadDriverPrizesTable(loggedInMngtData?.companyId));

          //credits acquired by drivers
          dispatch(loadDriverCredits(loggedInMngtData?.companyId))
        }

        const scoreOverviewEnabled=response.body?.find((item) => item.name === "score_overview") ? true : false;
        if(scoreOverviewEnabled){
          dispatch(getBehaviorsOfCompany(loggedInMngtData?.companyId))
        }
      })
   }
