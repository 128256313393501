import { DAILY_SCORES_FETCHED, DAILY_SCORES_RESETTED } from "../actions/scores";

// let filteredScores;
// let uniqueBehaviorNames;
// let duplicateBehaviorNames;
// let dataWithUniqueBehaviors;
// let dataWithSameBehavior;
// let highestComment;
// let filteredBehavior;
let sortedArray;

export default (state = null, action = {}) => {
    switch (action.type) {
        case DAILY_SCORES_FETCHED:
            //sort response based on starting dateof scores.
            sortedArray = action.payload?.map((behaviour) => {
                const sortedScores = behaviour?.scores?.sort(function (a, b) {
                    // nameA = a[0].start;
                    // nameB = b[0].start;
                    if (a.start < b.start) {
                        return -1;
                    }
                    if (a.start > b.start) {
                        return 1;
                    }
                    // if the names are equal, don't sort
                    return 0;
                })
                return {
                    ...behaviour,
                    scores: [...sortedScores]
                }
            }
            )
            // console.log("SortedArray", sortedArray)
            return sortedArray;

        // removing duplicate scores
        // behaviorNames = [
        //   ...new Set(filteredScores.map((score) => score.behavior.name)),
        // ];
        // reducedScores = behaviorNames.map((name) => {
        //   return filteredScores.find((score) => score.behavior.name === name);
        // });

        // return filteredScores;
        case DAILY_SCORES_RESETTED:
            return null;
        default:
            return state;
    }
};
