import {
  DRIVER_STATS_FETCHED,
  DRIVER_STATS_RESETTED,
} from "../actions/driverStats";

export default (state = null, action = {}) => {
  switch (action.type) {
    case DRIVER_STATS_FETCHED:
      if (action.payload && action.payload.length > 0) {
        return action.payload;
      } else {
        return [];
      }
    case DRIVER_STATS_RESETTED:
      return null;
    default:
      return state;
  }
};
