import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

let accounts;

// fetching a driver's last login date
export const LAST_LOGIN_DATE_FETCHED = "LAST_LOGIN_DATE_FETCHED";

const lastLoggedInDateFetched = (date) => ({
  type: LAST_LOGIN_DATE_FETCHED,
  payload: date,
});

// resetting the driver's last login date already loaded
export const LAST_LOGIN_DATE_RESETTED = "LAST_LOGIN_DATE_RESETTED";

export const lastLoggedInDateReset = () => ({
  type: LAST_LOGIN_DATE_RESETTED,
});

export const loadLastLoggedInDate =
  (driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const { driver } = store;
    const { loggedInDate } = store;
    let token = auth?.accessToken;

    // const dummyData = "2020-08-28T07:48:48.184+00:00";
    //   const dummyData = [];

    // Check if the last logged in date and a driver is already loaded
    if (driver && loggedInDate) {
      // Check if the request is for the same driver
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no date from before
        return;
      }
    }

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(`${baseURL}/Portal/Coaching/Users/LastLoggedIn?DriverId=${driverId}`)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from last logged in API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(lastLoggedInDateFetched(response.body));
      })
      .catch(console.error);
  };
