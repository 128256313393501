import {
  COACH_LANGUAGE_FETCHED,
  COACH_LANGUAGE_UPDATED
} from "../commonActions/coach_language";

export default (state = null, action = {}) => {
  switch (action.type) {
    case COACH_LANGUAGE_FETCHED:
      return action.payload;
    case COACH_LANGUAGE_UPDATED:
      return {
        ...state,
        lang: action.payload
      }
    default:
      return state;
  }
};
