import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import {applyMiddleware, createStore} from 'redux'
import "./index.css";
import App from "./App";
// import app from './reducers'
import * as serviceWorker from "./serviceWorker";
// import apiService from './services/apiService';
import store from "./services/store";
// Import a pre-configured instance of i18next:
import "./i18n";
import { authProvider } from "./authProvider";
import { MsalProvider } from "@azure/msal-react";

// const store = createStore(app, {}, applyMiddleware(apiService));

ReactDOM.render(
  <Suspense fallback={null}>
    <Provider store={store}>
      <MsalProvider instance={authProvider}>
        <App />
      </MsalProvider>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
