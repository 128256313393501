import request from "superagent";
import { authProvider } from "../../../../authProvider";
import { baseURL } from "../../../../services/constants";
import { getAccessToken } from "../../../../services/commonFunctions";

let accounts;

// fetching the rank of a single driver
export const DRIVER_RANK_CREATED = "DRIVER_RANK_CREATED";

const driverRankCreated = (rank) => ({
    type: DRIVER_RANK_CREATED,
    payload: rank,
});

// resetting the driver rank already loaded
export const DRIVER_RANK_RESETTED = "DRIVER_RANK_RESETTED";

export const driverRankReset = () => ({
    type: DRIVER_RANK_RESETTED,
});

export const loadDriverRank = (driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const { driverInManagement, driverRank } = store;
    let token = auth?.accessToken;

    // Check if the last logged in date and a driver is already loaded
    if (driverInManagement && driverRank) {
        // Check if the request is for the same driver
        if (driverInManagement.driverId === driverId) {
            // Don't send the request if the same driver is already loaded with no date from before
            return;
        }
    }

    // Get accessToken
    if (auth != null) {
        if (auth.accessToken == null) {
            try {
                accounts = authProvider.getAllAccounts();
                token = await getAccessToken(accounts, authProvider);
                auth = getState().auth;
            } catch (e) {
                console.log(e);
            }
        }
    }

    // Sending the request with today's date
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;
    const day = new Date().getDate();

    request
        .get(
            `${baseURL}/Portal/Management/Scores/GetRankingForDriver?DriverId=${driverId}&year=${year}&month=${month}&day=${day}`
        )
        .set("Authorization", `Bearer ${token}`)
        .retry(1, (err, res) => {
            if (res.unauthorized) {
                console.log(`401 error, retry from API, err is ${err}`);
                dispatch({
                    type: "ACQUIRE_TOKEN_FAILURE",
                    payload: null,
                });
            }
        })
        .then((response) => {
            dispatch(driverRankCreated(response.body));
        })
        .catch(() => {
            // until API is not sending back null as response
            dispatch(driverRankCreated(null));
        });
};
