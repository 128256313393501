//import React from 'react'
import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";

//import credits from '../../../assets/img/brand/Credits badge.png';
import { getAccessToken } from "../../../services/commonFunctions";
import i18next from "i18next";

let accounts;

// prizes table is loading something
export const PRIZES_TABLE_LOADING_ON = "PRIZES_TABLE_LOADING_ON";

const prizesTableIsLoading = () => ({
  type: PRIZES_TABLE_LOADING_ON,
});

// prizes table is not loading anymore
export const PRIZES_TABLE_LOADING_OFF = "PRIZES_TABLE_LOADING_OFF";

const prizesTableIsNotLoading = () => ({
  type: PRIZES_TABLE_LOADING_OFF,
});

// fetching all the prizes for Management portal's driver table/tab
export const PRIZES_TABLE_FETCHED = "PRIZES_TABLE_FETCHED";

const loadDriverPrizesTableFetch = (prizes) => ({
  type: PRIZES_TABLE_FETCHED,
  payload: prizes,
});

export const NOT_ACTIVATED_LOYALTY = "NOT_ACTIVATED_LOYALTY";

const handleNotActivatedLoyalty = () => ({
  type: NOT_ACTIVATED_LOYALTY,
  payload: null,
});

export const ACTIVATED_LOYALTY = "ACTIVATED_LOYALTY";

const handleActivatedLoyalty = () => ({
  type: ACTIVATED_LOYALTY,
  payload: null,
});

export const loadDriverPrizesTable =
  (companyId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const { prizesTable } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    request
      .get(
        `${baseURL}/portal/management/Prizes/GetPrizes/?companyId=${companyId}`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from a prizes table API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then(dispatch(handleActivatedLoyalty()))
      .then((response) => {
        if (prizesTable && prizesTable.length > 0) {
          if (prizesTable.length === response.body.length) {
            return;
          }
        }
        dispatch(loadDriverPrizesTableFetch(response.body));
      })
      .catch((e) => {
        if (e.response.status === 401) {
          dispatch(handleNotActivatedLoyalty());
        } else {
          console.log(e.response);
          alert(
            i18next.t("translation:ErrorMsg.15", {
              errorMsg: e.message,
              errorCode: e.response.statusCode,
            })
          );
        }
      });
  };

/// Get the groups of the manager
export const GROUPS_FETCHED = "GROUPS_FETCHED";

const loadGroupsOfUserFetch = (groups) => ({
  type: GROUPS_FETCHED,
  payload: groups,
});

export const loadManagerGroups = () => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  // const { prizesTable } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(`${baseURL}/portal/management/Groups/GetGroupsForPrizesOfUser`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(
          `401 error, retry from a get groups of user api, err is ${err}`
        );
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(loadGroupsOfUserFetch(response.body));
    })
    .catch((e) => {
      console.log(`error in getting company groups: ${e.response}`);
      alert(
        i18next.t("translation:ErrorMsg.15", {
          // old error code, fix later
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

// adding a new prizes to the table (with the table form, so only 1 at the time manually typing in)
export const PRIZE_EDITED = "PRIZE_EDITED";

const prizeEdited = (newData) => ({
  type: PRIZE_EDITED,
  payload: newData,
});

export const editPrize = (newData) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { loggedInMngtData } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  // console.log("Editing prize:", newData);
  dispatch(prizesTableIsLoading());

  const companyId = loggedInMngtData.companyId;
  //JSON object to be sent along with AddPrize api
  const prizeToEdit = {
    PrizeId: newData.prizeId,
    CompanyId: companyId,
    Active: newData.active,
    prizeName: newData.name,
    Image: newData.PrizeImage,
    Quantity: newData.quantity,
    Difficulty: newData.difficulty,
    Info: newData.info,
    IsGroupPrize: newData.groupPrize, // not working yet?
    CompanyGroupId: newData.group,
  };

  request
    .post(`${baseURL}/portal/management/Prizes/AddPrize`)
    .set("Content-Type", "application/json")
    .send(prizeToEdit)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a prizes table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(prizesTableIsNotLoading());
      // Below is related to drivers that need to drive at least 1 trip to have data. Seems irrelevant for prizes.
      if (response.ok) {
        // console.log("Edit prize response:", response.body);
        const formattedResponse = {
          PrizeImage: response.body.image,
          prizeId: response.body.prizesId, // weird: what's the 's' doing there?
          name: response.body.name,
          quantity: response.body.quantity,
          difficulty: response.body.difficulty,
          info: response.body.info,
          groupPrize: response.body.isGroupPrize,
          active: response.body.active,
          group: response.body.companyGroupId,
          groupName: response.body.companyGroup?.name,
          companyId: response.body.companyId
        };
        dispatch(prizeEdited(formattedResponse));
      } else {
        alert(i18next.t("translation:ErrorMsg.6"), response.statusType);
      }
    })
    .catch((e) => {
      dispatch(prizesTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.11", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

// adding a new prizes to the table (with the table form, so only 1 at the time manually typing in)
export const NEW_PRIZE_ADDED = "NEW_PRIZE_ADDED";

const newPrizesAdded = (newData) => ({
  type: NEW_PRIZE_ADDED,
  payload: newData,
});

export const addNewPrize = (newData) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { loggedInMngtData } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  // console.log("Adding new prize:", newData);
  dispatch(prizesTableIsLoading());

  const companyId = loggedInMngtData.companyId;
  //JSON object to be sent along with AddPrize api
  const prizeToAdd = {
    CompanyId: companyId,
    Active: newData.active,
    prizeName: newData.name,
    Image: newData.PrizeImage,
    Quantity: newData.quantity,
    Difficulty: newData.difficulty,
    Info: newData.info,
    IsGroupPrize: newData.groupPrize, // not working yet?
    CompanyGroupId: newData.group,
  };

  request
    .post(`${baseURL}/portal/management/Prizes/AddPrize`)
    .set("Content-Type", "application/json")
    .send(prizeToAdd)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a prizes table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(prizesTableIsNotLoading());
      // Below is related to drivers that need to drive at least 1 trip to have data. Seems irrelevant for prizes.
      if (response.ok) {
        // console.log("Add prize response:", response.body);
        const formattedResponse = {
          PrizeImage: response.body.image,
          prizeId: response.body.prizesId, // weird: what's the 's' doing there?
          name: response.body.name,
          quantity: response.body.quantity,
          difficulty: response.body.difficulty,
          info: response.body.info,
          groupPrize: response.body.isGroupPrize,
          active: response.body.active,
          group: response.body.companyGroupId,
          groupName: response.body.companyGroup?.name,
          companyId: response.body.companyId
        };

        dispatch(newPrizesAdded(formattedResponse));
      } else {
        alert(i18next.t("translation:ErrorMsg.6"), response.statusType);
      }
    })
    .catch((e) => {
      dispatch(prizesTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.11", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

///////  REMOVE PRIZES VIA API ///////////////////////////////////////////////////////////////////////

// removing a driver from the table
export const PRIZE_REMOVED = "PRIZE_REMOVED";

const prizeRemoved = (prizeId) => ({
  type: PRIZE_REMOVED,
  payload: prizeId,
});

export const removePrize = (prize) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(prizesTableIsLoading());

  const payLoad = {
    PrizeId: prize.prizeId,
    CompanyId: prize.companyId
  }

  request
    .post(`${baseURL}/portal/management/Prizes/RemovePrize`)
    .set("Content-Type", "application/json")
    .send(payLoad)
    // .send('{\r\n"PrizeId":"' + prizeId + '"\r\n}')
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a prizes table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(prizesTableIsNotLoading());

      if (response.ok) {
        dispatch(prizeRemoved(prize.prizeId));
      } else {
        alert(i18next.t("translation:ErrorMsg.17"), response.statusType);
      }
    })
    .catch((e) => {
      // Show proper error message in case prize cannot be removed because there's still active transactions
      if (e.response.statusCode === 500) {
        alert(i18next.t("translation:ErrorMsg.19"));
      } else {
        dispatch(prizesTableIsNotLoading());
        alert(
          i18next.t("translation:ErrorMsg.16", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
          })
        );
      }
    });
};
