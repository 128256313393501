import {
  MESSAGES_LOADING_TRUE,
  MESSAGES_LOADING_FALSE,
  MESSAGES_LOADING_NO_MORE,
} from "../actions/messages";

export default (state = false, action = {}) => {
  switch (action.type) {
    case MESSAGES_LOADING_TRUE:
      return true;
    case MESSAGES_LOADING_FALSE:
      return false;
    case MESSAGES_LOADING_NO_MORE:
      return null;
    default:
      return state;
  }
};
