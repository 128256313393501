import { DRIVER_LOADING_TRUE, DRIVER_LOADING_FALSE } from "../actions/driver";

export default (state = false, action = {}) => {
  switch (action.type) {
    case DRIVER_LOADING_TRUE:
      return true;
    case DRIVER_LOADING_FALSE:
      return false;
    default:
      return state;
  }
};
