import { COMPANIES_THRESHOLDS_FETCHED, RESET_THRESHOLDS_TO_INITIAL_STATE, UPDATE_THRESHOLD_SUCCESS } from "../actions/companyGroups";

// const companiesThresholds = [];
export default (state = null, action = {}) => {
  switch (action.type) {
    case COMPANIES_THRESHOLDS_FETCHED: 
      return action.payload;
    case RESET_THRESHOLDS_TO_INITIAL_STATE:
      return null;
    case UPDATE_THRESHOLD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};