import {
  NOT_ACTIVATED_LOYALTY,
  ACTIVATED_LOYALTY,
} from "../actions/driverPrizesTable";

export default (state = null, action = {}) => {
  switch (action.type) {
    case NOT_ACTIVATED_LOYALTY:
      return false;
    case ACTIVATED_LOYALTY:
      return true;
    default:
      return state;
  }
};
