import {
  DRIVERS_TABLE_LOADING_ON,
  DRIVERS_TABLE_LOADING_OFF,
} from "../actions/driversTable";

export default (state = false, action = {}) => {
  switch (action.type) {
    case DRIVERS_TABLE_LOADING_ON:
      return true;

    case DRIVERS_TABLE_LOADING_OFF:
      return false;

    default:
      return state;
  }
};
