export default (state = null, action = {}) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        account: action.payload.account,
        accessToken: action.payload.accessToken,
        expiresOn: action.payload.expiresOn,
        idToken: action.payload.idToken,
      };
    case "LOGIN_FAILURE":
      if (
        action.payload.errorMessage != null &&
        action.payload.errorMessage.includes("AADB2C90091")
      ) {
        // After clicking on "Forgot password", then cancelling the flow, this
        // catches it and redirects to home page "/"
        window.location.pathname = "";
        window.location.reload();
      }
      console.log(
        `Login failure: code is ${action.payload.errorCode}, error is: ${action.payload.errorMessage} and ${action.payload.suberror}`
      );
      return { ...state, accessToken: null, account: null };
    case "ACQUIRE_TOKEN_SUCCESS":
      return {
        ...state,
        account: action.payload.account,
        accessToken: action.payload.accessToken,
        expiresOn: action.payload.expiresOn,
        idToken: action.payload.idToken,
      };
    case "ACQUIRE_TOKEN_FAILURE":
      // window.location.pathname = "";
      // window.location.reload();
      return {
        ...state,
        account: null,
        accessToken: null,
        expiresOn: null,
        idToken: null,
      };
    case "LOGOUT_SUCCESS":
      return {
        ...state,
        account: null,
        accessToken: null,
        expiresOn: null,
        idToken: null,
      };
    case "REFRESH_TOKEN_FAILURE":
      console.log("Silent refresh failure, auth becoming null.");
      return null;
    default:
      return state;
  }
};
// end of react-aad-msal
