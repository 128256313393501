import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

let accounts;

// fetching available features for a company
export const COMPANY_FEATURES_FETCHED = "COMPANY_FEATURES_FETCHED";

const companyFeaturesFetched = (data) => ({
    type: COMPANY_FEATURES_FETCHED,
    payload: data,
});

// // resetting the driver's last login date already loaded
// export const COMPANY_FEATURES_RESETTED = "COMPANY_FEATURES_RESETTED";

// export const companyFeaturesReset = () => ({
//     type: COMPANY_FEATURES_RESETTED,
// });

export const loadCompanyFeatures =
    (companyId, companyName) => async (dispatch, getState) => {
        const store = getState();
        let { auth } = store;
        // const { companyFeaturesCoaching } = store;
        // const { loggedInDate } = store;
        let token = auth?.accessToken;

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }

        request
            .get(`${baseURL}/Portal/Coaching/features/GetFeaturesAvailable?companyId=${companyId}`)
            .set("Authorization", `Bearer ${token}`)
            .retry(1, (err, res) => {
                if (res.unauthorized) {
                    console.log(
                        `401 error, retry from last logged in API, err is ${err}`
                    );
                    dispatch({
                        type: "ACQUIRE_TOKEN_FAILURE",
                        payload: null,
                    });
                }
            })
            .then((response) => {
                dispatch(companyFeaturesFetched({ companyId: companyId, features: response.body, companyName: companyName }));
            })
            .catch(console.error);
    };
