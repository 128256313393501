/*
 * required polyfills
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/symbol'
// import 'core-js/es6/object'
// import 'core-js/es6/function'
// import 'core-js/es6/parse-int'
// import 'core-js/es6/parse-float'
// import 'core-js/es6/number'
// import 'core-js/es6/math'
// import 'core-js/es6/string'
// import 'core-js/es6/date'
import "core-js/es/array";
// import 'core-js/es6/regexp'
import "core-js/es/map";
// import 'core-js/es6/weak-map'
import "core-js/es/set";
import "core-js/es/object";

/** IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect'

/** Evergreen browsers require these. **/
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
// import 'core-js/es7/reflect'

// CustomEvent() constructor functionality in IE9, IE10, IE11
(function () {
  if (typeof window.CustomEvent === "function") return false;

  /**
   * Defines custom events.
   * @param {string} event The event to be handled.
   * @param {string} params The params for the event.
   * @returns {AnimationEvent | AnimationPlaybackEvent | AudioProcessingEvent | BeforeUnloadEvent | ClipboardEvent | CloseEvent | CompositionEvent | CustomEvent | DeviceLightEvent | DeviceMotionEvent | DeviceOrientationEvent | DragEvent | ErrorEvent | Event | FocusEvent | FocusNavigationEvent | GamepadEvent | HashChangeEvent | IDBVersionChangeEvent | KeyboardEvent | ListeningStateChangedEvent | MSGestureEvent | MSMediaKeyMessageEvent | MSMediaKeyNeededEvent | MSPointerEvent | MediaEncryptedEvent | MediaKeyMessageEvent | MediaQueryListEvent | MediaStreamErrorEvent | MediaStreamEvent | MediaStreamTrackEvent | MessageEvent | MouseEvent | MutationEvent | OfflineAudioCompletionEvent | OverflowEvent | PageTransitionEvent | PaymentRequestUpdateEvent | PermissionRequestedEvent | PointerEvent | PopStateEvent | ProgressEvent | PromiseRejectionEvent | RTCDTMFToneChangeEvent | RTCDataChannelEvent | RTCDtlsTransportStateChangedEvent | RTCErrorEvent | RTCIceCandidatePairChangedEvent | RTCIceGathererEvent | RTCIceTransportStateChangedEvent | RTCPeerConnectionIceErrorEvent | RTCPeerConnectionIceEvent | RTCSsrcConflictEvent | RTCStatsEvent | RTCTrackEvent | SVGZoomEvent | SecurityPolicyViolationEvent | ServiceWorkerMessageEvent | SpeechRecognitionError | SpeechRecognitionEvent | SpeechSynthesisErrorEvent | SpeechSynthesisEvent | StorageEvent | TextEvent | TouchEvent | TrackEvent | TransitionEvent | UIEvent | VRDisplayEvent | WebGLContextEvent | WheelEvent} The correct result event.
   * @constructor
   */
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();
