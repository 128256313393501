import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

let accounts;

// fetching a driver's outlier scores of the latest week
export const OUTLIER_SCORES_FETCHED = "OUTLIER_SCORES_FETCHED";

const outlierScoresFetched = (scores) => ({
  type: OUTLIER_SCORES_FETCHED,
  payload: scores,
});

// resetting the outlier scores already loaded
export const OUTLIER_SCORES_RESETTED = "OUTLIER_SCORES_RESETTED";

export const outlierScoresReset = () => ({
  type: OUTLIER_SCORES_RESETTED,
});

export const loadOutlierScores = (driverId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { driver } = store;
  const { outlierScores } = store;
  let token = auth?.accessToken;

  // Check if scores and a driver is already loaded
  if (driver && outlierScores) {
    // Check if the request is for the same driver
    if (driver.driverId === driverId) {
      // Don't send the request if the same driver is already loaded with no scores from before
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/coaching/Scores/GetOutlierScoresForDriver?driverId=${driverId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from outlier scores API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(outlierScoresFetched(response.body));
    })
    .catch(console.error);
};
