//import React from 'react'
import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";

//import credits from '../../../assets/img/brand/Credits badge.png';
import { getAccessToken } from "../../../services/commonFunctions";
import i18next from "i18next";

/// Get the achievements of the drivers
export const ACHIEVEMENTS_FETCHED = "ACHIEVEMENTS_FETCHED";

const loadAchievementsFetch = (achievements) => ({
  type: ACHIEVEMENTS_FETCHED,
  payload: achievements,
});

let accounts;

export const loadAchievements = (companyId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/management/Prizes/GetAvailableAchievements?companyId=${companyId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(
          `401 error, retry from a get achievements of user api, err is ${err}`
        );
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      // console.log("achievments response",response)
      dispatch(loadAchievementsFetch(response.body));
    })
    .catch((e) => {
      console.log(`error in getting company groups: ${e.response}`);
      alert(
        i18next.t("translation:ErrorMsg.18", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};
