import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "./Utils/hooks";
import { updateCoachLanguage } from "../../services/commonActions/coach_language";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// updating sidebar navigation language
export const LANG_CHANGE = "LANG_CHANGE";

/**
 * Creates a functional component containing the language switcher buttons.
 * @param {any} props The props passed for this functional component.
 * @returns {any} Returns a functional component.
 */
export default function LanguageSwitcher() {
  const { i18n } = useTranslation();
  const coachInfo = useSelector((state) => state.coachInfo);
  const [selectedLanguage, setSelectedLanguage] = useState(coachInfo && coachInfo.lang ? coachInfo.lang : "nl");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const languageChanged = (selectedLanguage) => ({
    type: LANG_CHANGE,
    payload: selectedLanguage,
  });

  useEffect(() => {
    if (coachInfo && coachInfo.lang && coachInfo.lang === "nl-NL") {
      i18n.changeLanguage("nl");
      setSelectedLanguage("nl");
    } else if (coachInfo && coachInfo.lang) {
      i18n.changeLanguage(coachInfo.lang);
      setSelectedLanguage(coachInfo.lang);
    } else {
      i18n.changeLanguage("nl");
    }
  }, [coachInfo]);

  const dispatch = useDispatch();

  const onLanguageSelect = (language) => {
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
    dispatch(languageChanged(language));
    dispatch(updateCoachLanguage(language === "nl" ? "nl-NL" : language === "de" ? "de" : "en"));
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="mr-5">
      <DropdownToggle caret color="outline-secondary" size="sm" className="shadow-sm">
        {selectedLanguage === "nl" && (
          <i className="flag-icon flag-icon-nl h5 mb-0" title="nl" id="nl"></i>
        )}
        {selectedLanguage === "de" && (
          <i className="flag-icon flag-icon-de h5 mb-0" title="de" id="de"></i>
        )}
        {selectedLanguage === "en" && (
          <i className="flag-icon flag-icon-gb h5 mb-0" title="en" id="en"></i>
        )}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onLanguageSelect("nl")}>
          <i className="flag-icon flag-icon-nl h5 mb-0 mr-2" title="nl" id="nl"></i> Dutch
        </DropdownItem>
        <DropdownItem onClick={() => onLanguageSelect("de")}>
          <i className="flag-icon flag-icon-de h5 mb-0 mr-2" title="de" id="de"></i> German
        </DropdownItem>
        <DropdownItem onClick={() => onLanguageSelect("en")}>
          <i className="flag-icon flag-icon-gb h5 mb-0 mr-2" title="gb" id="gb"></i> English
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}