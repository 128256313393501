import {
  DRIVER_FETCHED_MNG,
  DRIVER_RESETTED_MNG,
  // DRIVER_POSTPONED_STATUS_ADDED,
  // DRIVER_POSTPONED_STATUS_REMOVED,
  // DRIVER_UNREAD_STATUS_ADDED,
  // DRIVER_UNREAD_STATUS_REMOVED,
  // DRIVER_NEWDRIVER_STATUS_ADDED,
  // DRIVER_NEWDRIVER_STATUS_REMOVED,
  // DRIVER_OVERDUE_STATUS_REMOVED
} from "../actions/driverData";

//let driverToBeUpdated;

export default (state = null, action = {}) => {
  switch (action.type) {
    case DRIVER_FETCHED_MNG:
      return action.payload;
    // case DRIVER_POSTPONED_STATUS_ADDED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       postponed: true,
    //     },
    //   };
    // case DRIVER_POSTPONED_STATUS_REMOVED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       postponed: false,
    //     },
    //   };
    // case DRIVER_UNREAD_STATUS_ADDED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       unread: true,
    //     },
    //   };
    // case DRIVER_UNREAD_STATUS_REMOVED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       unread: false,
    //     },
    //   };
    // case DRIVER_NEWDRIVER_STATUS_ADDED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       newDriver: true,
    //     },
    //   };
    // case DRIVER_NEWDRIVER_STATUS_REMOVED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       newDriver: false,
    //     },
    //   };
    // case DRIVER_OVERDUE_STATUS_REMOVED:
    //   return {
    //     ...state,
    //     status: {
    //       ...state.status,
    //       overdue: false,
    //     },
    //   };
    case DRIVER_RESETTED_MNG:
      return null;
    default:
      return state;
  }
};
