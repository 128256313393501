import { BEHAVIORS_FETCHED } from "../actions/scoreOverview";


export default (state = null, action) => {
    switch (action.type) {
        case BEHAVIORS_FETCHED:
            return action.payload;
        default:
            return state;
    }
}