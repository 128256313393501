import { MNGT_DATA_FETCHED } from "../actions/loggedInMngtData";

export default (state = null, action = {}) => {
  switch (action.type) {
    case MNGT_DATA_FETCHED:
      return { companyId: action.payload };
    default:
      return state;
  }
};
