import { TOGGLE_PROFILE_LOADING } from "../actions/driversTable";

let loading = false;
export default (state = loading, action = {}) => {
    switch (action.type) {
        case TOGGLE_PROFILE_LOADING:
            return !state;
      default:
    return state;
}
  };