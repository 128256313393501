import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";
import { openIssueStatusAddedToDriver, openIssueStatusRemovedFromDriver } from "./driver";
import { openIssueStatusAdded, openIssueStatusRemoved } from "./drivers";
import i18next from "i18next";

let accounts;

//Issues fetched
export const SDISSUES_FETCHED="SDISSUES_FETCHED"

const sdIssuesFetched = ({issues,driverId}) => ({
    type: SDISSUES_FETCHED,
    payload: {issues,driverId},
  });

  export const SDISSUES_RESETTED="SDISSUES_RESETTED";
  const IssuesReset = () => ({
    type: SDISSUES_RESETTED,
  });

  export const ISSUE_CREATE_SUCCESS="ISSUE_CREATE_SUCCESS";

  const issueAdded=({issue,driveId})=>({
    type:ISSUE_CREATE_SUCCESS,
    payload:{issue,driveId}
  })

export const reportSDIssue =
    (requestBody) => async (dispatch, getState) => {
        const store = getState();
        let { auth } = store;
        let token = auth?.accessToken;


        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }

        const body = {
            "Title": requestBody?.newIssueTitle,
            "Description": requestBody?.newIssueDescription,
            "CompanyId": requestBody?.companyId,
            "DriverId": requestBody?.driverId
        }
request
    .post(`${baseURL}/portal/coaching/Issue/CreateIssue`)
    .set("Content-Type", "application/json")
    .send(body)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
        dispatch(issueAdded({issue:response.body,driverId:requestBody?.driverId}))
        dispatch(openIssueStatusAddedToDriver());
        dispatch(openIssueStatusAdded(requestBody?.companyName,requestBody?.driverId))
        alert(
            i18next.t("translation:SDIssues.6")
          );
    })
    .catch(console.error);
  };


  export const getDriverIssues =
    (requestBody) => async (dispatch, getState) => {
        const store = getState();
        let { auth,sdIssues } = store;
        let token = auth?.accessToken;

        if(sdIssues && requestBody.driverId){
            if(sdIssues?.driverId === requestBody.driverId){
                return;
            }else{
                dispatch(IssuesReset())
            }
        }

        // Get accessToken
        if (auth != null) {
            if (auth.accessToken == null) {
                try {
                    accounts = authProvider.getAllAccounts();
                    token = await getAccessToken(accounts, authProvider);
                    auth = getState().auth;
                } catch (e) {
                    console.log(e);
                }
            }
        }

request
    .get(`${baseURL}/portal/coaching/Issue/GetIssuesOfDriver?driverId=${requestBody.driverId}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
        if (res.unauthorized) {
            console.log(
                `401 error, retry from last logged in API, err is ${err}`
            );
            dispatch({
                type: "ACQUIRE_TOKEN_FAILURE",
                payload: null,
            });
        }
    })
    .then((response) => {
        dispatch(sdIssuesFetched({issues:response.body,driverId:requestBody.driverId}))
        const findOpenIssue=response?.body?.find((issue)=>issue.issue.state === "opened");
        if(findOpenIssue===undefined){
            dispatch(openIssueStatusRemovedFromDriver());
            dispatch(openIssueStatusRemoved(requestBody?.companyName,requestBody?.driverId));
        }
    })
    .catch(console.error);
  };
