import request from "superagent";
//import { baseURL } from "../../../services/constants";
import { outlierScoresReset } from "./outlierScoresMng";
import { scoresReset } from "./scoresMng";
import { driverStatsReset, driverStatsHistoryReset } from "./driverStatsData";
// import { lastLoggedInDateReset } from "./loggedInDate";
import { driverRankReset } from "./driverRankMng";
// import { messagesReset } from "./messages";
import { baseURL } from "../../../../services/constants";
import { lastLoggedInDateReset } from "./lastLoggedInDateMng";

// fetching a single driver
export const DRIVER_FETCHED_MNG = "DRIVER_FETCHED_MNG";

const driverFetched = (driver) => ({
  type: DRIVER_FETCHED_MNG,
  payload: driver,
});


// resetting the driver already loaded
export const DRIVER_RESETTED_MNG = "DRIVER_RESETTED_MNG";

const driverReset = () => {
  return { type: DRIVER_RESETTED_MNG };
};

// setting the loader to true
export const DRIVER_LOADING_TRUE_MNG = "DRIVER_LOADING_TRUE_MNG";

const setDriverIsLoading = () => ({
  type: DRIVER_LOADING_TRUE_MNG,
});

// setting the loader to true
export const DRIVER_LOADING_FALSE_MNG = "DRIVER_LOADING_FALSE_MNG";

const setDriverIsNotLoading = () => ({
  type: DRIVER_LOADING_FALSE_MNG,
});


export const loadDriver = (driverId) => async (dispatch, getState) => {
  const store = getState();
  const { auth } = store;
  const { driverInManagement } = store;
  const { driverLoadingInMng } = store;
  let token = auth?.accessToken;

  // Check if a driver is already loaded
  if (driverInManagement) {
    // If it's the same driver which wants to reload, don't send the API request
    if (driverInManagement.driverId === driverId) {
      return;
    } else {
      // Delete driver if another driver is going to be loaded
      dispatch(driverReset());
      // reset the outlier scores if the scores loaded are from a different driver
      dispatch(outlierScoresReset());
      // reset the scores if the scores loaded are from a different driver
      dispatch(scoresReset());
      // reset driver stats if another driver data is going to be loaded
      dispatch(driverStatsReset());
      dispatch(driverStatsHistoryReset());
      dispatch(lastLoggedInDateReset());
      dispatch(driverRankReset());
      // dispatch(messagesReset());
    }
  }

  if (!driverLoadingInMng) {
    dispatch(setDriverIsLoading());
    request
      .get(
        `${baseURL}/portal/management/Drivers/GetDriver?id=${driverId}&includePersonalDetails=true&includeCompany=true&includeStatuses=true`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(driverFetched(response.body));
        dispatch(setDriverIsNotLoading());
      })
      .catch((e) => {
        console.error(e), dispatch(setDriverIsNotLoading());
      });
  }
};
