import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

let accounts;

// fetching the graphs
export const GRAPHS_FETCHED = "DASHBOARD_GRAPHS_FETCHED";
export const DashboardPieChartFetched = "DASHBOARD_PIE_CHART_FETCHED";
export const DashboardAllScoresDataFetched = "DASHBOARD_ALLSCORES_DATA_FETCHED";
export const DashboardMinGoalSettingsDataFetched = "_DASHBOARD_MINGOAL_DATA_FETCHED";
export const DashboardAllStatsDataFetched = "DASHBOARD_ALLSTATS_DATA_FETCHED";
// export const dashboardDataResetted = "DASHBOARD_DATA_RESETTED"


const DashboardPieDataFetched = ({ data, company }) => ({
  type: DashboardPieChartFetched,
  payload: { data, company },
});

const DashboardAllScoresData = ({ data, company }) => ({
  type: DashboardAllScoresDataFetched,
  payload: { data, company },
});

const DashboardAllStatsData = ({ data, company }) => ({
  type: DashboardAllStatsDataFetched,
  payload: { data, company },
});

const DashboardMinGoalSettingsData = ({ data, company }) => ({
  type: DashboardMinGoalSettingsDataFetched,
  payload: { data, company },
});

// const resetDashboardData = () => ({
//   type: dashboardDataResetted,
// });



// export const getGraphs = () => async (dispatch, getState) => {
//   const store = getState();
//   let { auth } = store;
//   let token = auth?.accessToken;

//   // Get accessToken
// if (auth != null) {
//   if (auth.accessToken == null) {
//     try {
//       token = await getAccessToken(accounts, authProvider);
//       auth = getState().auth;
//     } catch (e) {
//       console.log(e);
//     }
//   }
// }

//   request
//     .get(`${baseURL}/portal/management/Graphs/getGraphs`)
//     .set("Authorization", `Bearer ${token}`)
//     .retry(1, (err, res) => {
//       if (res.unauthorized) {
//         console.log(`401 error, retry from graphs API, err is ${err}`);
//         dispatch({
//           type: "ACQUIRE_TOKEN_FAILURE",
//           payload: null,
//         });
//       }
//     })
//     .then((response) => {
//       dispatch(graphsFetched(response.body));
//       console.log("action body",response.body)
//     })
//     .catch(console.error);
// };
// }

export const fetchPieDataForDashboard = (scoreDistributionBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth, scoreDistributionGraph } = store;
  let token = auth?.accessToken;

  // let currentCompanyDetails = store.graphsForDashboard?.companyData;

  if (scoreDistributionGraph &&
    scoreDistributionGraph.companyData &&
    Object.keys(scoreDistributionGraph.companyData).length !== 0) {
    const currentCompanyDetails = scoreDistributionGraph.companyData;
    if (
      scoreDistributionBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      scoreDistributionBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      scoreDistributionBody?.From === currentCompanyDetails?.From &&
      scoreDistributionBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .post(`${baseURL}/portal/coaching/Graphs/ScoreDistributionGraph`)
    .set("Content-Type", "application/json")
    .send(scoreDistributionBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(DashboardPieDataFetched({ data: response.body, company: scoreDistributionBody }));
    })
    .catch(console.error);
};


export const fetchAllScoresDataForDashboard = (AllScoresBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth, allScoresGraph } = store;
  let token = auth?.accessToken;
  if (allScoresGraph &&
    allScoresGraph.companyData &&
    Object.keys(allScoresGraph.companyData).length !== 0) {
    const currentCompanyDetails = allScoresGraph.companyData;
    if (
      AllScoresBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      AllScoresBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      AllScoresBody?.From === currentCompanyDetails?.From  &&
      AllScoresBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  // const data={
  //   From : "2022-10-04",
  //   To : "2022-11-04",
  //   CompanyId: "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //   CompanyGroupId : null
  // }

  request
    .post(`${baseURL}/portal/coaching/Graphs/AllScoresGraph`)
    .set("Content-Type", "application/json")
    .send(AllScoresBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(DashboardAllScoresData({ data: response.body, company: AllScoresBody }));
    })
    .catch(console.error);
};

export const fetchMinGoalSettingsDataForDashboard = (minGoalSettingsBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth, minGoalGraph } = store;
  let token = auth?.accessToken;

  if (minGoalGraph &&
    minGoalGraph.companyData &&
    Object.keys(minGoalGraph.companyData).length !== 0) {
    const currentCompanyDetails =minGoalGraph.companyData;
    if (
      minGoalSettingsBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      minGoalSettingsBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      minGoalSettingsBody?.From === currentCompanyDetails?.From &&
      minGoalSettingsBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }


  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  // const data={
  //   From : "2022-10-04",
  //   To : "2022-11-04",
  //   CompanyId: "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //   CompanyGroupId : null
  // }

  request
    .post(`${baseURL}/portal/Coaching/Graphs/MinGoalSettingGraph`)
    .set("Content-Type", "application/json")
    .send(minGoalSettingsBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(DashboardMinGoalSettingsData({ data: response.body, company: minGoalSettingsBody }));
    })
    .catch(console.error);
};

export const fetchAllStatsDataForDashboard = (AllStatsBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth, allStatsGraph } = store;
  let token = auth?.accessToken;

  if (allStatsGraph &&
    allStatsGraph.companyData ) {
    const currentCompanyDetails = allStatsGraph.companyData;
    if (
      AllStatsBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      AllStatsBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      AllStatsBody?.From === currentCompanyDetails?.From &&
      AllStatsBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  // 

  // const data={
  //   From : "2022-10-04",
  //   To : "2022-11-04",
  //   CompanyId: "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //   CompanyGroupId : null
  // }

  request
    .post(`${baseURL}/portal/coaching/Graphs/AllStatsGraph`)
    .set("Content-Type", "application/json")
    .send(AllStatsBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(DashboardAllStatsData({ data: response.body, company: AllStatsBody }));
      // console.log("graph all scores data",response.body)
    })
    .catch(console.error);
};