import React from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import "./App.scss";
// import { AzureAD } from "react-aad-msal";
import { InteractionType } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { useMsalAuthentication } from "@azure/msal-react";
import { useSelector } from "./views/Coaching/Utils/hooks";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const LoadingPage = React.lazy(() =>
  import("./views/Pages/Loading/LoadingPage")
);
// const Page404 = React.lazy(() => import("./views/Pages/Page404"));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const App = () => {
  const {
    login,
    // result,
    error,
  } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
  const {
    // instance, accounts,
    inProgress,
  } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const auth = useSelector((state) => state.auth);

  React.useEffect(() => {
    if (inProgress === "none" && (!auth || error)) {
      // if (inProgress === "none" && (error)) {
      // checking if password reset is in action => no need, as it's configured in Azure in our signin policy
      //   if (error && error.errorMessage.indexOf("AADB2C90118") > -1) {
      //     const request = {
      //         authority: "your-b2c-authority/your-password-reset-policy"
      //     }
      //     login(InteractionType.Popup, request)
      // } else { // if not password reset, just continue with login
      login(InteractionType.Redirect, loginRequest);
      // }
    }
  }, [error, inProgress, auth]);

  return (
    <HashRouter>
      <React.Suspense fallback={loading()}>
        <Switch>
          {/*auth check before redirecting to defaultLayout to synchronise msal actions and store actions. Introduced this fix after upgrading msal-react from 1.0.0 to 1.5.4 */}
          {isAuthenticated && auth && (
            <Route
              path="/"
              name="Home"
              render={(props) => <DefaultLayout {...props} />}
            />
          )}
          {!isAuthenticated && (
            <Route
              name="Loading"
              render={(props) => <LoadingPage {...props} />}
            />
          )}
          {/* <Route name="Page 404" render={(props) => <Page404 {...props} />} /> */}
          {/*<Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />}/>*/}
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
