import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

// const accounts = authProvider.getAllAccounts();

let accounts;

// fetching the graphs
export const GRAPHS_FETCHED = "GRAPHS_FETCHED";
export const PieChartFetched = "PIE_CHART_FETCHED";
export const AllScoresDataFetched = "ALLSCORES_DATA_FETCHED";
export const MinGoalSettingsDataFetched = "MINGOAL_DATA_FETCHED";
export const AllStatsDataFetched = "ALLSTATS_DATA_FETCHED";

// const graphsFetched = (graphs) => ({
//   type: GRAPHS_FETCHED,
//   payload: graphs,
// });


const PieDataFetched = (data) => ({
  type: PieChartFetched,
  payload: data,
});

const AllScoresData = (data) => ({
  type: AllScoresDataFetched,
  payload: data,
});

const AllStatsData = (data) => ({
  type: AllStatsDataFetched,
  payload: data,
});

const MinGoalSettingsData = ({ data, company }) => ({
  type: MinGoalSettingsDataFetched,
  payload: { data, company },
});

// export const getGraphs = () => async (dispatch, getState) => {
//   const store = getState();
//   let { auth } = store;
//   let token = auth?.accessToken;

//   // Get accessToken
// if (auth != null) {
//   if (auth.accessToken == null) {
//     try {
//       token = await getAccessToken(accounts, authProvider);
//       auth = getState().auth;
//     } catch (e) {
//       console.log(e);
//     }
//   }
// }

//   request
//     .get(`${baseURL}/portal/management/Graphs/getGraphs`)
//     .set("Authorization", `Bearer ${token}`)
//     .retry(1, (err, res) => {
//       if (res.unauthorized) {
//         console.log(`401 error, retry from graphs API, err is ${err}`);
//         dispatch({
//           type: "ACQUIRE_TOKEN_FAILURE",
//           payload: null,
//         });
//       }
//     })
//     .then((response) => {
//       dispatch(graphsFetched(response.body));
//       console.log("action body",response.body)
//     })
//     .catch(console.error);
// };


export const fetchPieData = (scoreDistributionBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  // let { expiresOn } = auth;
  let token = auth?.accessToken;

  let currentCompanyDetails = store.graphs?.companyData;

  if (Object.keys(currentCompanyDetails).length !== 0) {
    if (
      scoreDistributionBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      scoreDistributionBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      scoreDistributionBody?.From === currentCompanyDetails?.From &&
      scoreDistributionBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }


  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  // console.log("body in action",scoreDistributionBody)

  request
    .post(`${baseURL}/portal/management/Graphs/ScoreDistributionGraph`)
    .set("Content-Type", "application/json")
    .send(scoreDistributionBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
        } catch (e) {
          console.log(e)
        }
        // dispatch({
        //   type: "ACQUIRE_TOKEN_FAILURE",
        //   payload: null,
        // });
      }
    })
    .then((response) => {
      dispatch(PieDataFetched(response.body));
    })
    .catch(console.error);
};


export const fetchAllScoresData = (AllScoresBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  let currentCompanyDetails = store.graphs?.companyData;

  if (Object.keys(currentCompanyDetails).length !== 0) {
    if (
      AllScoresBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      AllScoresBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      AllScoresBody?.From === currentCompanyDetails?.From &&
      AllScoresBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  // const data={
  //   From : "2022-10-04",
  //   To : "2022-11-04",
  //   CompanyId: "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //   CompanyGroupId : null
  // }

  request
    .post(`${baseURL}/portal/management/Graphs/AllScoresGraph`)
    .set("Content-Type", "application/json")
    .send(AllScoresBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
        } catch (e) {
          console.log(e)
        }
        // dispatch({
        //   type: "ACQUIRE_TOKEN_FAILURE",
        //   payload: null,
        // });
      }
    })
    .then((response) => {
      dispatch(AllScoresData(response.body));
    })
    .catch(console.error);
};

export const fetchMinGoalSettingsData = (minGoalSettingsBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
  let currentCompanyDetails = store.graphs?.companyData;

  if (Object.keys(currentCompanyDetails).length !== 0) {
    if (
      minGoalSettingsBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      minGoalSettingsBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      minGoalSettingsBody?.From === currentCompanyDetails?.From &&
      minGoalSettingsBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }


  // const data={
  //   From : "2022-10-04",
  //   To : "2022-11-04",
  //   CompanyId: "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //   CompanyGroupId : null
  // }

  request
    .post(`${baseURL}/portal/management/Graphs/MinGoalSettingGraph`)
    .set("Content-Type", "application/json")
    .send(minGoalSettingsBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
        } catch (e) {
          console.log(e)
        }
        // dispatch({
        //   type: "ACQUIRE_TOKEN_FAILURE",
        //   payload: null,
        // });
      }
    })
    .then((response) => {
      dispatch(MinGoalSettingsData({ data: response.body, company: minGoalSettingsBody }));
    })
    .catch(console.error);
};

export const fetchAllStatsData = (AllStatsBody) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
  let currentCompanyDetails = store.graphs?.companyData;

  if (Object.keys(currentCompanyDetails).length !== 0) {
    if (
      AllStatsBody?.CompanyId === currentCompanyDetails?.CompanyId &&
      AllStatsBody?.companyGroupId === currentCompanyDetails?.companyGroupId &&
      AllStatsBody?.From === currentCompanyDetails?.From &&
      AllStatsBody?.To === currentCompanyDetails?.To
    ) {
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  // 

  // const data={
  //   From : "2022-10-04",
  //   To : "2022-11-04",
  //   CompanyId: "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //   CompanyGroupId : null
  // }

  request
    .post(`${baseURL}/portal/management/Graphs/AllStatsGraph`)
    .set("Content-Type", "application/json")
    .send(AllStatsBody)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, async (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from graphs API, err is ${err}`);
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
        } catch (e) {
          console.log(e)
        }
        // dispatch({
        //   type: "ACQUIRE_TOKEN_FAILURE",
        //   payload: null,
        // });
      }
    })
    .then((response) => {
      dispatch(AllStatsData(response.body));
      // console.log("graph all scores data",response.body)
    })
    .catch(console.error);
};