import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import Papa from "papaparse";
import {
  languageParserFromCsv,
  phoneNumParserFromCsv,
} from "../../Coaching/Utils/functions";
import i18next from "i18next";
import { getAccessToken } from "../../../services/commonFunctions";
// import { useSelector } from "react-redux";

let accounts;

const papaConfig = {
  quoteChar: "'",
  escapeChar: "'",
  delimiter: ";",
  skipEmptyLines: "greedy",
};

// drivers table is loading something
export const DRIVERS_TABLE_LOADING_ON = "DRIVERS_TABLE_LOADING_ON";

const driversTableIsLoading = () => ({
  type: DRIVERS_TABLE_LOADING_ON,
});

// drivers table is not loading anymore
export const DRIVERS_TABLE_LOADING_OFF = "DRIVERS_TABLE_LOADING_OFF";

const driversTableIsNotLoading = () => ({
  type: DRIVERS_TABLE_LOADING_OFF,
});

// fetching all the drivers for Management portal's driver table/tab
export const DRIVERS_TABLE_FETCHED = "DRIVERS_TABLE_FETCHED";
export const DRIVER_PROFILE_FETCHED = "DRIVER_PROFILE_FETCHED";
export const RESET_TO_INITIAL_STATE = "RESET_TO_INITIAL_STATE";
export const TOGGLE_PROFILE_LOADING = "TOGGLE_PROFILE_LOADING";

const toggleProfileLoading = () => ({
  type: TOGGLE_PROFILE_LOADING,
})

const driversTableFetched = (drivers) => ({
  type: DRIVERS_TABLE_FETCHED,
  payload: drivers,
});

const driverProfileFetched = (driverrpofile) => ({
  type: DRIVER_PROFILE_FETCHED,
  payload: driverrpofile,
});

export const resetDataToInitialState = () => ({
  type: RESET_TO_INITIAL_STATE,
  payload: [],
});

export const loadDriversTable = (companyId) => async (dispatch, getState) => {
  // const dummyDriversTable = [
  //   {
  //     driverId: "1",
  //     firstName: "Mark",
  //     lastName: "Atto",
  //     phone: "06-32345678",
  //     email: "default@gmail.com",
  //     companyId: "25637",
  //   },
  // ]; // prev format

  // const dummyDriversTable = [ // current format
  //   {
  //     "personalDetails": {
  //         "displayName": "John Doe",
  //         "givenName": "John",
  //         "identities": [
  //             {
  //                 "issuer": "sdinsightsusers.onmicrosoft.com",
  //                 "issuerAssignedId": "jdoe@ziggo.nl", // email
  //                 "signInType": "emailAddress",
  //                 "@odata.type": "microsoft.graph.objectIdentity"
  //             },
  //             {
  //                 "issuer": "sdinsightsusers.onmicrosoft.com",
  //                 "issuerAssignedId": "132ebdf4-6ff6-4c2b-a0d4-e2c5847a92cc@sdinsightsusers.onmicrosoft.com",
  //                 "signInType": "userPrincipalName",
  //                 "@odata.type": "microsoft.graph.objectIdentity"
  //             }
  //         ],
  //         "surname": "Doe",
  //         "id": "132ebdf4-6ff6-4c2b-a0d4-e2c5847a92cc",
  //         "@odata.type": "microsoft.graph.user"
  //     },
  //     "latestWeekScore": null,
  //     "driverId": "ca97b279-4c93-4507-8cde-33af34390788", // driver ID
  //     "azureAdObjectId": "132ebdf4-6ff6-4c2b-a0d4-e2c5847a92cc",
  //     "companyDriverId": "38990 + 001", // company Driver ID
  //     "companyId": "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //     "company": {
  //         "companyId": "47b1fb8c-7b7d-4420-8646-69f7853704da",
  //         "azureAdCompanyId": 9,
  //         "companyName": "ABC Logistics",
  //         "provider": 2,
  //         "index": "next-tran-ecoperf-abc",
  //         "suffix": "abc",
  //         "statsIndex": "next-tran-stats-weekly-abc",
  //         "visualizations": [],
  //     },
  //     "notes": [],
  //     "status": null,
  //     "outlierScores": null,
  //   },
  // ];
  // await dispatch(driversTableFetched(dummyDriversTable));

  // //********************* with API:
  const store = getState();
  let { auth } = store;
  const { driversTable } = store;
  // const { roles } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/management/Drivers/GetDrivers/?companyId=${companyId}`
    ) // test company ID: 443325EE-786A-41C1-9BC8-D6D446A0AF75
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      if (driversTable && driversTable.length > 0) {
        if (driversTable.length === response.body.length) {
          return;
        }
      }
      // console.log("response from drivers api",response)

      dispatch(driversTableFetched(response.body))
    })
    .catch((e) => {
      console.log(e);
      alert(
        i18next.t("translation:ErrorMsg.3", {
          errorMsg: e.message,
          errorCode: e.response?.statusCode,
        })
      );
    });
};

// toggle edit mode for a driver if it'll be in Redux (it is now)
export const DRIVER_EDITMODE_TOGGLED = "DRIVER_EDITMODE_TOGGLED";

const driverEditmodeToggled = (driverId) => ({
  type: DRIVER_EDITMODE_TOGGLED,
  payload: driverId,
});

export const toggleEditmode = (driverId) => async (dispatch) => {
  await dispatch(driverEditmodeToggled(driverId));
};

// updating info about a driver
export const DRIVERS_TABLE_UDPATED = "DRIVERS_TABLE_UDPATED";

const driversTableUpdated = (newData, driverId) => ({
  type: DRIVERS_TABLE_UDPATED,
  payload: { data: newData, driverId: driverId },
});

export const updateDriversTable =
  (newData, driverId) => async (dispatch, getState) => {
    const store = getState();
    let { auth, companyGroups } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    //assigning default group if company has no group
    newData.groupDisplayName = companyGroups?.length === 1 ? companyGroups[0].displayName : newData.groupDisplayName;

    // console.log("newdata in action", newData)
    //getting groupId as editdriver API needs groupId
    const groupInfo = companyGroups?.find((group) => group.displayName === newData.groupDisplayName)
    // console.log("new group in action", groupInfo)
    // console.log("group info",groupInfo)
    dispatch(driversTableIsLoading());

    const urlToCall = `${baseURL}/portal/management/Drivers/UpdateDriver?`
      + `driverId=${driverId}`
      + `&newCompanyDriverId=${encodeURIComponent(newData.companyId)}`
      + `&newGivenName=${newData.firstName}`
      + `&newSurname=${newData.lastName}`
      + `&newMobilePhone=${phoneNumParserFromCsv(newData.phone)}`
      + `&newDisplayName=${newData.firstName + " " + newData.lastName}`
      + `&newLanguageCode=${newData.lang}`
      + `&companyGroupId=${groupInfo?.groupId}`;

    request
      .put(urlToCall)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from a drivers table API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(driversTableIsNotLoading());

        if (response.ok) {
          const formattedResponse = {
            driverId: driverId,
            firstName: response.body[0].givenName,
            lastName: response.body[0].surname,
            phone: response.body[0].mobilePhone
              ? response.body[0].mobilePhone
              : "",
            email: newData.email,
            group: response.body[1].companyGroup?.name,
            groupDisplayName: response.body[1].companyGroup?.displayName,
            companyId: response.body[1].companyDriverId,
            lang: newData.lang, // will it be in response?
            editMode: false,
          };
          // console.log("response from update driver api", response);
          dispatch(driversTableUpdated(formattedResponse, driverId));
        } else {
          alert(i18next.t("translation:ErrorMsg.4"), response.statusType);
        }
        // response format is:
        //   [
        //     {
        //         "displayName": "Adel Doh",
        //         "givenName": "Leda",
        //         "mobilePhone": "33770412505",
        //         "surname": "Doh",
        //         "@odata.type": "microsoft.graph.user"
        //     },
        //     {
        //         "driverId": "3ca1e5bd-9f60-408e-b34a-000c09662cbe",
        //         "azureAdObjectId": "97396e14-98ff-410a-9434-91a6ca9bd67b",
        //         "fmsDriverId": 874,
        //         "companyDriverId": "874",
        //         "companyId": "443325ee-786a-41c1-9bc8-d6d446a0af75",
        //         "companyDTO": null,
        //         "language": null,
        //         "trips": [],
        //         "scores": [],
        //         "devices": null,
        //         "notes": null,
        //         "status": null,
        //         "outlierScores": null
        //     }
        // ]
      })
      .catch((e) => {
        dispatch(driversTableIsNotLoading());
        console.log(e.response);
        alert(
          i18next.t("translation:ErrorMsg.5", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
          })
        );
      });
  };

// adding a new driver to the table (with the table form, so only 1 at the time manually typing in)
export const NEW_DRIVER_ADDED = "NEW_DRIVER_ADDED";

const newDriverAdded = (newData) => ({
  type: NEW_DRIVER_ADDED,
  payload: newData,
});

export const addNewDriver = (newData) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { loggedInMngtData, companyGroups } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(driversTableIsLoading());

  newData.firstName = newData?.firstName?.trim();
  newData.lastName = newData?.lastName?.trim();

  const companyId = loggedInMngtData.companyId;
  let groupToAssign = null;
  let groupName;

  if (companyGroups) {
    if (companyGroups.length === 1) {
      groupToAssign = companyGroups[0].name
    }
    else if (companyGroups.length === 2) {
      groupToAssign = companyGroups.find((group) => group.parentGroupId !== null)?.name
    }
    else {
      groupName = companyGroups.find((group) => group.displayName === newData.group)?.name
      groupToAssign = groupName;
    }
  }


  // a long string format with line break like so:
  /* "Roepnaam;Achternaam;Displaynaam;Telefoon mobiel;Emailadres;driver_ref
Adel;Doh;Adel Doh;adel@gmail.com;33770412505;874" */
  // => driver_ref is companyDriverId

  const dataToSend = Papa.unparse(
    [
      {
        Roepnaam: newData.firstName,
        Achternaam: newData.lastName,
        Displaynaam: newData.firstName + " " + newData.lastName,
        "Telefoon mobiel": newData.email, // email end mobile fields switched on purpose (that's how backend expects)
        Emailadres: phoneNumParserFromCsv(newData.phone),
        driver_ref: newData.companyId,
        lang: newData.lang,
        group: groupToAssign,
      },
    ],
    papaConfig
  );

  // console.log("dataTosend", dataToSend)
  // ! Replace test url with production api url before merging with master
  request
    .post(
      `${baseURL}/portal/admin/B2CUsers/PostBulk?companyId=${companyId}`
    )
    .set("Content-Type", "application/json") // throws error 415 (unsupported media type) and complains that the form is not connected, the json type throws 400 bad request as it's not a json
    .send('"' + dataToSend + '"')
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driversTableIsNotLoading());
      if (response.ok) {
        let driverIdText;
        if (response.body.length === 1) {
          if (
            response.body[0].statusCode === 404 ||
            response.body[0].statusCode === 409
          ) {
            // because although backend sends back 200, the driver was not linked in Azure (404) or the driver already has an AzureId (409)
            alert(i18next.t("translation:ErrorMsg.1"));
            return;
          }
          driverIdText = response.body[0].message;
        } else {
          if (
            response.body[1].statusCode === 404 ||
            response.body[0].statusCode === 409
          ) {
            alert(i18next.t("translation:ErrorMsg.1"));
            return;
          }
          driverIdText = response.body[1].message;
        }

        // in case of duplication:
        if (driverIdText.includes("was already linked")) {
          // "message": "User Testt ForrReall (3761d6d7-9b34-4a31-b255-e6d43e949f48) was already linked.",
          alert(`Error: ${driverIdText}`);
          return;
        }

        // "User Adel Doh (1a03d11d-b0ac-434a-a3bc-b8bf30c495d4) linked to driver (3ca1e5bd-9f60-408e-b34a-000c09662cbe) with AzureId (1a03d11d-b0ac-434a-a3bc-b8bf30c495d4)"
        const indexOfId = driverIdText.split(" ").indexOf("driver") + 1;
        const driverIdFromText = driverIdText
          .split(" ")[indexOfId].slice(1, -1);

        // const newDriverGroup=companyGroups?.find((group)=>group.groupId===groupToAssign)
        const formattedResponse = {
          driverId: driverIdFromText,
          firstName: newData.firstName,
          lastName: newData.lastName,
          phone: phoneNumParserFromCsv(newData.phone),
          email: newData.email,
          companyId: newData.companyId,
          lang: newData.lang,
          editMode: false,
          group: groupToAssign,
          groupDisplayName: newData.group,
        };

        dispatch(newDriverAdded(formattedResponse));
      } else {
        alert(i18next.t("translation:ErrorMsg.6"), response.statusType);
      }

      // response format is: (OR just the 2nd object!)
      //   [
      //     {
      //         "statusCode": 200,
      //         "message": "POST",
      //         "exception": null,
      //         "user": {
      //             "businessPhones": [],
      //             "displayName": "Adel Doh",
      //             "givenName": "Adel",
      //             "mobilePhone": "33770412505",
      //             "surname": "Doh",
      //..........
      //             },
      //             "statusCode": "Created"
      //         }
      //     },
      //     {
      //         "statusCode": 201,
      //         "message": "User Adel Doh (1a03d11d-b0ac-434a-a3bc-b8bf30c495d4) linked to driver (3ca1e5bd-9f60-408e-b34a-000c09662cbe) with AzureId (1a03d11d-b0ac-434a-a3bc-b8bf30c495d4)",
      //         "exception": null,
      //         "user": null
      //     }
      // ]
    })
    .catch((e) => {
      dispatch(driversTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.11", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

// removing a driver from the table
export const DRIVER_REMOVED = "DRIVER_REMOVED";
export const DRIVERS_REMOVED = "DRIVERS_REMOVED";

const driverRemoved = (driverId) => ({
  type: DRIVER_REMOVED,
  payload: driverId,
});
const driversRemoved = (driverIdList) => ({
  type: DRIVERS_REMOVED,
  payload: driverIdList,
});

export const removeDriver = (driverId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(driversTableIsLoading());

  request
    .delete(`${baseURL}/portal/admin/B2CUsers/DeleteUser?driverId=${driverId}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driversTableIsNotLoading());

      if (response.ok) {
        dispatch(driverRemoved(driverId));
      } else {
        alert(i18next.t("translation:ErrorMsg.12"), response.statusType);
      }
      // response format:
      //   {
      //     "statusCode": 200,
      //     "message": "User deleted",
      //     "exception": null,
      //     "user": null
      // }
    })
    .catch((e) => {
      dispatch(driversTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.22", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

export const removeDrivers = (driverIdList) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
  const drivers = driverIdList;
  
  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  if(driverIdList?.firstName){
    driverIdList = [driverIdList.driverId];
  } else {
    driverIdList = Array.from(driverIdList, (obj) => obj.driverId);
  }
  dispatch(driversTableIsLoading());

  request
    .post(`${baseURL}/portal/admin/B2CUsers/DeleteUsers`)
    .set("Content-Type", "application/json")
    .send(driverIdList)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driversTableIsNotLoading());

      if (response.ok) {
        if(response.body.errors?.length){
          let invalidDriverids = Array.from(response.body.errors, (obj) => obj.driverId);
          const invalidDriverNames = drivers
            .filter(driver => invalidDriverids.includes(driver.driverId))
            .map(driver => driver.firstName);
          alert(i18next.t("translation:ErrorMsg.21")+`: ${invalidDriverNames?.join(', ')}`)
        }
        dispatch(driversRemoved(driverIdList));
      } else {
        alert(i18next.t("translation:ErrorMsg.12"), response?.statusType);
      }
    })
    .catch((e) => {
      dispatch(driversTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.22", {
          errorMsg: e.message,
          errorCode: e.response?.statusCode,
        })
      );
    });
};

// sorting the drivers' table based on various options
export const SORTING_CHANGED = "SORTING_CHANGED";

const sortingChanged = (keyword) => ({
  type: SORTING_CHANGED,
  payload: keyword,
});

export const changeSorting = (keyword) => async (dispatch) => {
  await dispatch(sortingChanged(keyword));
};

// importing driver data from CSV file (similar to adding a single driver, but it's multiple drivers)
export const DRIVERS_CSV_DATA_IMPORTED = "DRIVERS_CSV_DATA_IMPORTED";

const driversCsvDataImported = (importConfig, newData, arrayOfDriverIds) => ({
  type: DRIVERS_CSV_DATA_IMPORTED,
  payload: {
    fileData: newData,
    importConfig: importConfig,
    arrayOfDriverIds: arrayOfDriverIds,
  },
});

export const importDriversDataFromCsvFile =
  (importConfig, newData) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    const { loggedInMngtData } = store;
    let token = auth?.accessToken;

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }

    dispatch(driversTableIsLoading());

    const companyId = loggedInMngtData.companyId; // test company id: "443325EE-786A-41C1-9BC8-D6D446A0AF75"

    // newData format from CSV parser:
    // [
    //   ['Adi', 'T', '83457234', 'email@email.com', '874', 'English'], // a row, a driver's data
    //   ['TestFirstName', 'K', '83457234', 'a@email.com', '123456', 'French'],
    //  ]

    // a long string format with line break like so:
    /* "Roepnaam;Achternaam;Displaynaam;Telefoon mobiel;Emailadres;driver_ref;lang
Adel;Doee;Adel Doee;adel@testtt.com;23423333333;874;en
Jane;Dee;Jane Dee;jane@test.com;46374857463;123456;fr" */

    const formattedDataToSend = newData.map((driverDataRowArray) => {
      return {
        Roepnaam: driverDataRowArray[0],
        Achternaam: driverDataRowArray[1],
        Displaynaam: driverDataRowArray[0] + " " + driverDataRowArray[1],
        "Telefoon mobiel": driverDataRowArray[3], // email end mobile fields switched on purpose (that's how backend expects)
        Emailadres: phoneNumParserFromCsv(driverDataRowArray[2]), // this is the mobile value
        driver_ref: driverDataRowArray[4],
        lang: languageParserFromCsv(driverDataRowArray[5]),
        group: driverDataRowArray[6] ? driverDataRowArray[6] : "",
      };
    });

    const dataToSend = Papa.unparse(formattedDataToSend, papaConfig);

    request
      .post(`${baseURL}/portal/admin/B2CUsers/PostBulk?companyId=${companyId}`)
      .set("Content-Type", "application/json") // has to be in json! but with quotation marks on both ends of the long string
      .send('"' + dataToSend + '"')
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from a drivers table API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(driversTableIsNotLoading());

        if (response.ok) {
          let importedData = newData;

          // checking if all drivers were linked correctly:
          const isAnyError = response.body.some(
            (object) => object.statusCode === 404
          );
          if (isAnyError) {
            // because although backend sends back 200, a driver was not linked in Azure (404)
            const errorForDriversArray = response.body.filter(
              (object) => object.statusCode === 404
            );
            const arrayOfErrorCompanyIds = errorForDriversArray.map(
              (object) => {
                const companyIdText = object.message;
                const indexOfId =
                  companyIdText.split(" ").indexOf("companyDriverId") + 1;
                const companyIdFromText = companyIdText
                  .split(" ")[indexOfId].slice(1, -1); // remove parenthesis
                return companyIdFromText;
              }
            );

            let filteredImportedData;
            filteredImportedData = importedData.filter(
              (driverDataRowArray) =>
                !arrayOfErrorCompanyIds.includes(driverDataRowArray[4])
            );

            importedData = filteredImportedData;

            const convertedArrayToString = arrayOfErrorCompanyIds
              .join()
              .toString();
            alert(
              i18next.t("translation:ErrorMsg.2", {
                convertedArrayToString: convertedArrayToString,
              })
            );
            // return; // no need to return, as backend still adds the correct driver data, we just had to filter out the ones that had errors
          }

          // checking if all drivers were linked correctly and no driver ID has an already linked Azure ID:
          const isAnyLinkingDuplicateError = response.body.some(
            (object) => object.statusCode === 409
          );
          if (isAnyLinkingDuplicateError) {
            // because although backend sends back 200, a driver already has an AzureId (409).
            const errorForDriversArray = response.body.filter(
              (object) => object.statusCode === 409
            );
            const arrayOfErroredDriverNames = errorForDriversArray.map(
              (object) => {
                const driverNameText = object.message.split(" "); // "message":"User Testt van ForrReall (c7ac19a8-35a5-477d-83dd-26b769e4b52b) not linked due to driver (3ca1e5bd-9f60-408e-b34a-000c09662cbe) already having AzureId (3761d6d7-9b34-4a31-b255-e6d43e949f48)"
                const indexOfId = driverNameText.indexOf("not") - 1;
                const driverNameFromText = driverNameText
                  .slice(1, indexOfId)
                  .join(" ");
                return driverNameFromText; // "Testt van ForrReall"
              }
            );

            let filteredImportedData;
            filteredImportedData = importedData.filter(
              (driverDataRowArray) =>
                !arrayOfErroredDriverNames.includes(
                  driverDataRowArray[0] + " " + driverDataRowArray[1]
                )
            );

            importedData = filteredImportedData;

            const convertedArrayToString = arrayOfErroredDriverNames
              .join()
              .toString();
            alert(
              i18next.t("translation:ErrorMsg.8", {
                convertedArrayToString: convertedArrayToString,
              })
            );
            // return; // no need to return, as backend still adds the correct driver data, we just had to filter out the ones that had errors
          }

          // in case of duplication:
          const isAnyDuplicate = response.body.some((object) =>
            object.message.includes("was already linked")
          );
          if (isAnyDuplicate) {
            // because although backend sends back 200, a driver was already linked in Azure.
            const duplicateDriversArray = response.body.filter((object) =>
              object.message.includes("was already linked")
            );
            const arrayOfDuplicateDriverNames = duplicateDriversArray.map(
              (object) => {
                const driverNameText = object.message.split(" "); // "message": "User Testt van ForrReall (3761d6d7-9b34-4a31-b255-e6d43e949f48) was already linked.",
                const indexOfId = driverNameText.indexOf("was") - 1;
                const driverNameFromText = driverNameText
                  .slice(1, indexOfId)
                  .join(" ");
                return driverNameFromText; // "Testt van ForrReall"
              }
            );

            let filteredImportedData;
            filteredImportedData = importedData.filter(
              (driverDataRowArray) =>
                !arrayOfDuplicateDriverNames.includes(
                  driverDataRowArray[0] + " " + driverDataRowArray[1]
                )
            );

            importedData = filteredImportedData;

            const convertedArrayToString = arrayOfDuplicateDriverNames
              .join()
              .toString();
            alert(
              i18next.t("translation:ErrorMsg.9", {
                convertedArrayToString: convertedArrayToString,
              })
            );
          }

          const driverIdObjects = response.body.filter(
            (object) => object.statusCode === 201
          );
          const arrayOfDriverIds = driverIdObjects.map((object) => {
            const driverIdText = object.message;
            const indexOfId = driverIdText.split(" ").indexOf("driver") + 1;
            const driverIdFromText = driverIdText
              .split(" ")[indexOfId].slice(1, -1);
            return driverIdFromText;
          });

          if (importedData.length > 0)
            dispatch(
              driversCsvDataImported(
                importConfig,
                importedData,
                arrayOfDriverIds
              )
            );
        } else {
          alert(i18next.t("translation:ErrorMsg.10"), response.statusType);
        }
      })
      .catch((e) => {
        dispatch(driversTableIsNotLoading());
        console.log(e.response);
        alert(
          i18next.t("translation:ErrorMsg.11", {
            errorMsg: e.message,
            errorCode: e.response.statusCode,
          })
        );
      });
  };

// resetting a PNL code user's password by deleting it from Azure
export const PWD_RESETTED = "PWD_RESETTED";

const pwdResetted = (pnlCode) => ({
  type: PWD_RESETTED,
  payload: pnlCode,
});

export const resetPwdForDriver = (pnlCode) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(driversTableIsLoading());

  request
    .delete(`${baseURL}/app/v7/PnlUser/DeletePNLUserFromAzure?pnl=${pnlCode}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driversTableIsNotLoading());
      if (response.ok) {
        dispatch(pwdResetted(pnlCode));
      }

      // response format is:
      //   {
      //     "statusCode": 200,
      //     "message": "User deleted",
      //     "exception": null,
      //     "user": null
      // }
    })
    .catch((e) => {
      dispatch(driversTableIsNotLoading());
      console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.13", {
          errorMsg: e.message,
          errorCode: e.response.statusCode,
        })
      );
    });
};

export const resetPwdForNonPnlDriver = (driverId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
  const drivers = driverId;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  dispatch(driversTableIsLoading());
  if(driverId?.length > 1 || !driverId?.firstName){
    driverId = Array.from(driverId, (obj) => obj.driverId);
  } else {
    driverId = [driverId?.driverId]
  }
  const body = driverId;
  request
    .post(`${baseURL}/portal/management/Drivers/ResetPassword`)
    .set("Content-Type", "application/json")
    .send(body)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driversTableIsNotLoading());
      if (response.ok) {
        if(response.body.errors?.length){
          let invalidDriverids = Array.from(response.body.errors, (obj) => obj.driverId);
          const invalidDriverNames = drivers
            .filter(driver => invalidDriverids.includes(driver.driverId))
            .map(driver => driver.firstName);
          alert(i18next.t("translation:ErrorMsg.20")+` : ${invalidDriverNames?.join(', ')}`)
        }
        // No response if successfull
      }
    })
    .catch((e) => {
      dispatch(driversTableIsNotLoading());
      // console.log(e.response);
      alert(
        i18next.t("translation:ErrorMsg.13", {
          errorMsg: e?.message,
          errorCode: e?.response?.statusCode,
        })
      );
    });
};

export const getDriverProfile = (driverId) => async (dispatch, getState) => {
  
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
  dispatch(toggleProfileLoading()); 
  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  dispatch(resetDataToInitialState());
  request
    .get(
      `${baseURL}/portal/management/Drivers/GetProfile?id=${driverId}`
    ) // https://empi-test.azurewebsites.net/api
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverProfileFetched(response.body))
      dispatch(toggleProfileLoading()); 
    })
    .catch((e) => {
      dispatch(driverProfileFetched({}))
      console.log(e);
      dispatch(toggleProfileLoading()); 
    });
};

export const updateDriverProfile = (driver) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;
  const driverProfile = driver;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  const body = driverProfile;
  request
    .post(`${baseURL}/portal/management/Drivers/UpdateProfile`)
    .set("Content-Type", "application/json")
    .send(body)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from a drivers table API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      if (response.ok) {
        if(response.body.errors?.length){          
          console.log(i18next.t("translation:ErrorMsg.20")+` : ${response.body.errors?.join(', ')}`)
        }
        console.log('successfully updated driver profile',response)
        // No response if successfull
      }
    })
    .catch((e) => {
      console.log(
        i18next.t("translation:ErrorMsg.13", {
          errorMsg: e?.message,
          errorCode: e?.response?.statusCode,
          error: e.response
        })
      );
    });
};