import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

let accounts;

// fetching a single driver's scores of the last 5 weeks
export const SCORE_HISTORY_FETCHED = "SCORE_HISTORY_FETCHED";
export const SCORE_PROGRESS_FETCHED = "SCORE_PROGRESS_FETCHED"

const scoresFetched = (scores) => ({
  type: SCORE_HISTORY_FETCHED,
  payload: scores,
});

const scoresProgressFetched = (scores) => ({
  type: SCORE_PROGRESS_FETCHED,
  payload: scores,
});

// resetting the score history already loaded
export const SCORE_HISTORY_RESETTED = "SCORE_HISTORY_RESETTED";
export const SCORE_PROGRESS_RESETTED = "SCORE_PROGRESS_RESETTED";

const scoreProgressReset = () => ({
  type: SCORE_PROGRESS_RESETTED,
});

const scoreHistoryReset = () => ({
  type: SCORE_HISTORY_RESETTED,
});

//this action thunk is used for fetching weeklyScores in 5 weekSpan 
export const loadScoreHistory = (driverId, weekSpan) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { driver, scoreHistory } = store;
  let token = auth?.accessToken;
  let endDate = getYearMonthDateObject(weekSpan)

  if (weekSpan === "initialLoad") {
    if (scoreHistory) {
      if (driver?.driverId === driverId) {
        // Don't send the request if the same driver is already loaded
        return;
      }
      else {
        // Delete score history if it's already loaded from another driver
        dispatch(scoreHistoryReset());
        dispatch(scoreProgressReset());
      }
    }
  }
  else {
    // Delete score history if it's already loaded from another driver
    dispatch(scoreHistoryReset());
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/coaching/Scores/GetScoresForDriver?driverId=${driverId}&year=${endDate.year}&month=${endDate.month}&day=${endDate.day}&weekspan=5`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from score history API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(scoresFetched(response.body));
      if (weekSpan === "initialLoad") {
        dispatch(scoresProgressFetched(response.body))
      }
    })
    .catch(console.error);
};

// // fetching a single driver's scores of the last 5 weeks
export const WEEKLY_SCORE_HISTORY_FETCHED = "WEEKLY_SCORE_HISTORY_FETCHED";

const weeklyScoresHistoryFetched = (scores) => ({
  type: WEEKLY_SCORE_HISTORY_FETCHED,
  payload: scores,
});

// resetting the score history already loaded
export const WEEKLY_SCORE_HISTORY_RESETTED = "WEEKLY_SCORE_HISTORY_RESETTED";

export const weeklyScoreHistoryReset = () => ({
  type: WEEKLY_SCORE_HISTORY_RESETTED,
});

//this action thunk is used for fetching weeklyScores in 3 weekSpan with different response format to be used in detailed scores tab
export const getWeeklyScoreHistory = (driverId, weekObject, weekSpan, companyId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { driver } = store;
  const { weeklyScoreHistory, coachingCompanyFeatures } = store;
  let token = auth?.accessToken;
  let endDate = getYearMonthDateObject(weekObject);

  if (weekObject === "initialLoad") {
    if (coachingCompanyFeatures) {
      const findCompany = coachingCompanyFeatures.find((company) => companyId?.toLowerCase()?.includes(company?.companyName.toLowerCase()))
      if (findCompany) {
        const findFeature = findCompany?.features?.find((feature) => feature.name === "daily_scores")
        if (!findFeature) {
          return;
        }
      }
    }
    weekSpan = 2;
    if (driver && weeklyScoreHistory) {
      if (driver.driverId === driverId) {
        // Don't send the request if the same driver is already loaded
        return;
      }
    } else {
      dispatch(weeklyScoreHistoryReset());
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/coaching/Scores/GetWeeklyScoresPerBehavior?driverId=${driverId}&weeksSpan=${weekSpan}&end=${endDate.year}-${endDate.month}-${endDate.day}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from score history API, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(weeklyScoresHistoryFetched({ data: response.body }));
    })
    .catch(console.error);
};

const getYearMonthDateObject = (weekObject) => {
  let todaysDate;
  if (weekObject === "initialLoad") {
    todaysDate = new Date();
  }
  else {
    // const date = new Date();

    // const getDateFromObject = getStartOfWeek(date.getFullYear(), weekObject?.endWeek + 1)
    todaysDate = new Date(weekObject)
    const dateObject = new Date(weekObject)
    todaysDate.setDate(dateObject.getDate() + 7)
  }

  return {
    year: todaysDate.getFullYear(),
    month: todaysDate.getMonth() + 1,
    day: todaysDate.getDate(),
  }
}

// const getStartOfWeek = (year, weekIndex) => {
//   const startDate = new Date(year, 0, 1); // January 1st of the given year
//   const dayOffset = startDate.getDay(); // 0 (Sunday) to 6 (Saturday)

//   const daysToAdd = weekIndex * 7 - dayOffset;
//   startDate.setDate(1 + daysToAdd); // Set to the first day of the desired week

//   return startDate;
// }
