import {
  NOTES_FETCHED,
  NOTES_RESETTED,
  NOTE_CREATE_SUCCESS,
  NOTE_UPDATE_SUCCESS,
  NOTE_HIDE_SUCCESS,
} from "../actions/notes";

let filterHiddenNotes = [];
let sortedByDateNotes = [];
let extendedNotes = [];
let filteredStateAfterHide = [];
let noteIndex = {};

export default (state = null, action = {}) => {
  switch (action.type) {
    case NOTES_FETCHED:
      filterHiddenNotes = action.payload.filter((note) => note.isVisible);
      sortedByDateNotes = filterHiddenNotes.sort(
        (a, b) => new Date(a.creationDateTime) - new Date(b.creationDateTime)
      );
      return sortedByDateNotes;
    case NOTES_RESETTED:
      return null;
    case NOTE_CREATE_SUCCESS:
      extendedNotes = [...state, action.payload];
      return extendedNotes;
    case NOTE_UPDATE_SUCCESS:
      noteIndex = state.findIndex(note => note.noteId === action.payload.noteId);
      if (noteIndex !== -1) {
        return [
          ...state.slice(0, noteIndex),
          action.payload,
          ...state.slice(noteIndex + 1)
        ];
      }
      break;
    case NOTE_HIDE_SUCCESS:
      filteredStateAfterHide = state.filter((note) => {
        return note.noteId !== action.payload.noteId;
      });
      return filteredStateAfterHide;
    default:
      return state;
  }
};
