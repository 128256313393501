import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";

import { getAccessToken } from "../../../services/commonFunctions";
import i18next from "i18next";

let accounts;


export const DRIVER_CREDITS_DATA_LOADED = "DRIVER_CREDITS_DATA_LOADED";

const loadDriverCreditsData = (data) => ({
    type:DRIVER_CREDITS_DATA_LOADED,
    payload: data,
  });

export const loadDriverCredits = (companyId) => async (dispatch, getState) => {
    const store = getState();
    let { auth } = store;
    let token = auth?.accessToken;
  

    // Get accessToken
    if (auth != null) {
      if (auth.accessToken == null) {
        try {
          accounts = authProvider.getAllAccounts();
          token = await getAccessToken(accounts, authProvider);
          auth = getState().auth;
        } catch (e) {
          console.log(e);
        }
      }
    }
  
    request
      .get(`${baseURL}/portal/management/Credits/GetDriversCreditsList?companyId=${companyId}`)
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(
            `401 error, retry from get drivercredits API, err is ${err}`
          );
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(loadDriverCreditsData(response.body));
      })
      .catch((e) => {
        console.log(`error in getting driver credits: ${e.response}`);
        alert(
          i18next.t("translation:ErrorMsg.15", {
            // old error code, fix later
            errorMsg: e.message,
            // errorCode: e.response.statusCode,
          })
        );
      });
  };
  