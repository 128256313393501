import {
    AVAILABLE_FEATURES_FETCHED
} from "../actions/getFeaturesAvailable";

export default (state = null, action = {}) => {
    switch (action.type) {
        case AVAILABLE_FEATURES_FETCHED:
            return action.payload;
        default:
          return state;
    }
}