import request from "superagent";
import { authProvider } from "../../../authProvider";
import { baseURL } from "../../../services/constants";
import { getAccessToken } from "../../../services/commonFunctions";

let accounts;

// fetching a single driver's notes
export const NOTES_FETCHED = "NOTES_FETCHED";

const notesFetched = (notes) => ({
  type: NOTES_FETCHED,
  payload: notes,
});

// resetting the notes already loaded
export const NOTES_RESETTED = "NOTES_RESETTED";

const notesReset = () => ({
  type: NOTES_RESETTED,
});

export const loadNotes = (driverId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { notes } = store;
  let token = auth?.accessToken;

  // Check if notes are already loaded
  if (notes && notes.length > 0) {
    // Check if the request is for the same driver
    if (notes[0].driverDriverId === driverId) {
      return;
    } else {
      // Delete notes if the driver loaded is not the same as the request & continue with the request
      dispatch(notesReset());
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/coaching/notes/GetNotesForDriver?driverId=${driverId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from notes APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      // dispatch(notesFetched(response.body));
      if (
        response.body.length > 0 &&
        response.body.filter((note) => note.isVisible).length > 0
      ) {
        dispatch(notesFetched(response.body));
      } else {
        dispatch(
          notesFetched([
            {
              driverDriverId: driverId,
              isVisible: true,
              creationDateTime: new Date(),
            },
          ])
        );
      }
    })
    .catch(console.error);
};

// adding a new note for a driver or for a company
export const NOTE_CREATE_SUCCESS = "NOTE_CREATE_SUCCESS";
export const NOTE_UPDATE_SUCCESS = "NOTE_UPDATE_SUCCESS";

const noteAdded = (note) => ({
  type: NOTE_CREATE_SUCCESS,
  payload: note,
});

const noteUpdated = (note) => ({
  type: NOTE_UPDATE_SUCCESS,
  payload: note,
});

export const addNote = (note, id, groupId, keyword) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  const dataToSend =
    keyword === "driver"
      ? {
        DriverDriverId: id,
        Content: note,
      }
      : keyword === "company" ? {
        CompanyCompanyId: id,
        Content: note,
      }:
      {
        CompanyCompanyId: id,
        Content: note,
        CompanyGroupGroupId: groupId,
      };

  request
    .post(`${baseURL}/portal/coaching/notes/PostNote`)
    .send(dataToSend)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from notes APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(noteAdded(response.body));
    })
    .catch(console.error);
};

// delete a note from a driver
export const NOTE_HIDE_SUCCESS = "NOTE_HIDE_SUCCESS";

const noteDeleted = (note) => ({
  type: NOTE_HIDE_SUCCESS,
  payload: note,
});

export const deleteNote = (noteId) => async (dispatch, getState) => {
  // no need to send the driverId, because the notes fetched in the store are from the already loaded driver
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .put(`${baseURL}/portal/coaching/notes/PutHideNote?noteId=${noteId}`)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from notes APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(noteDeleted(response.body)); // should include the noteId
    })
    .catch(console.error);
};

// fetching a company's notes
export const loadCompanyNotes = (companyId) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  const { notes } = store;
  let token = auth?.accessToken;

  // Check if notes are already loaded from this company
  if (notes && notes.length > 0) {
    // Check if the request is for the same company
    if (notes[0].companyCompanyId === companyId) {
      return;
    } else {
      // Delete notes if the company loaded is not the same as the request & continue with the request
      dispatch(notesReset());
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/portal/coaching/notes/GetNotesForCompany?companyId=${companyId}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from notes APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      if (
        response.body.length > 0 &&
        response.body.filter((note) => note.isVisible).length > 0
      ) {
        dispatch(notesFetched(response.body));
      } else {
        dispatch(
          notesFetched([
            {
              companyCompanyId: companyId,
              isVisible: true,
              creationDateTime: new Date(),
              
            },
          ])
        );
      }
    })
    .catch(console.error);
};

export const updateNote = (note, id) => async (dispatch, getState) => {
  const store = getState();
  let { auth } = store;
  let token = auth?.accessToken;

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }
  const dataToSend = {
    noteId : id,
    content: note
  }

  request
    .put(`${baseURL}/api/portal/coaching/Notes/UpdateNote`)
    .send(dataToSend)
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from notes APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(noteUpdated(response.body));
    })
    .catch(console.error);
};