import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/en-gb";
import "moment/locale/de";

const languages = ["nl", "en", "de"];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    /* can have multiple namespaces, for splitting up code and loading optimization(common,etc.) */
    languages,
    lng: "nl",
    fallbackLng: "nl", // use nl if detected lng is not available (if using LanguageDetector)
    // debug: true, // console logs the changes

    // keySeparator: false,

    interpolation: {
      escapeValue: false, // React already does escaping
      format: function (value, format, lng) {
        // formatting dates
        if (lng === "en") {
          moment.locale("en-gb");
        } else if (lng === "de") {
          moment.locale("de");
        }
        else {
          moment.locale(lng); // "nl"
        }
        if (value instanceof Date) return moment(value).format(format);
        return value;
      },
    },
    // react-i18next options: to not render the content until needed namespaces are loaded
    react: {
      // wait: true, <= deprecated
      useSuspense: true, // <= new way
    },
  });

export default i18n;
