import {
DRIVER_PROFILE_FETCHED,
RESET_TO_INITIAL_STATE
} from "../actions/drivers";

const initialState={
    driverProfile: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {      
      case DRIVER_PROFILE_FETCHED:
        return {
            ...initialState,
            driverProfile:action.payload
        };
      case RESET_TO_INITIAL_STATE:
        return {
            ...initialState,
        };
      default:
        return state;
    }
  };
  
  