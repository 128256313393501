import { TOGGLE_LOADING } from "../actions/scoreOverview";

let loading = false;
export default (state = loading, action = {}) => {
    switch (action.type) {
        case TOGGLE_LOADING:
            return !state;
      default:
    return state;
}
  };