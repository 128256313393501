import request from "superagent";
import { authProvider } from "../../../../authProvider";
import { baseURL } from "../../../../services/constants";
import { dateParser } from "../../../Coaching/Utils/functions";
import { getAccessToken } from "../../../../services/commonFunctions";

let accounts;

// fetching a driver's statistics (distance driven in km, percentage of long distance journeys)
export const DRIVER_STATS_FETCHED = "DRIVER_STATS_FETCHED";

const driverStatsFetched = (stats) => ({
  type: DRIVER_STATS_FETCHED,
  payload: stats,
});

// resetting the driver's statistics already loaded
export const DRIVER_STATS_RESETTED = "DRIVER_STATS_RESETTED";

export const driverStatsReset = () => ({
  type: DRIVER_STATS_RESETTED,
});

export const loadDriverStats = (driverId, date) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driverInManagement } = store;
  const { driverStats } = store;
  let dateObject = dateParser(date);
  let token = auth?.accessToken;

  if (date === "initialLoad") {
    dateObject = dateParser(new Date());
    // Check if scores and a driver is already loaded
    if (driverInManagement && driverStats) {
      // Check if the request is for the same driver
      if (driverInManagement.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetDriverCoachingStatistics?DriverId=${driverId}&year=${dateObject.year}&month=${dateObject.month}&day=${dateObject.day}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverStatsFetched(response.body));
      if (date === "initialLoad") {
        dispatch(driverStatsDateResetted()); // reset the stats history date range if we switched to a new driver
      }
    })
    .catch(console.error);
};

// changing the driver's statistics' date range
export const DRIVER_STATS_DATE_CHANGED = "DRIVER_STATS_DATE_CHANGED";

export const driverStatsDateChange = (dateObject) => ({
  type: DRIVER_STATS_DATE_CHANGED,
  payload: dateObject,
});

// resetting the driver's statistics' date range to the latest available date
export const DRIVER_STATS_DATE_RESETTED = "DRIVER_STATS_DATE_RESETTED";

export const driverStatsDateResetted = () => ({
  type: DRIVER_STATS_DATE_RESETTED,
});

// resetting the driver's statistics history already loaded
export const DRIVER_STATS_HISTORY_RESETTED = "DRIVER_STATS_HISTORY_RESETTED";

export const driverStatsHistoryReset = () => ({
  type: DRIVER_STATS_HISTORY_RESETTED,
});

// fetching a driver's statistics history (distance driven in km, percentage of long distance journeys)
export const DRIVER_STATS_HISTORY_FETCHED = "DRIVER_STATS_HISTORY_FETCHED";

const driverStatsHistoryFetched = (stats) => ({
  type: DRIVER_STATS_HISTORY_FETCHED,
  payload: stats,
});

export const loadDriverStatsHistory = (driverId, date) => async (
  dispatch,
  getState
) => {
  const store = getState();
  let { auth } = store;
  const { driverInManagement } = store;
  const { driverStats } = store;
  let dateObject = dateParser(date);
  let token = auth?.accessToken;

  if (date === "initialLoad") {
    dateObject = dateParser(new Date());
    // Check if scores and a driver is already loaded
    if (driverInManagement && driverStats) {
      // Check if the request is for the same driver
      if (driverInManagement.driverId === driverId) {
        // Don't send the request if the same driver is already loaded with no stats from before
        return;
      }
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  request
    .get(
      `${baseURL}/Portal/Management/Statistics/GetDriverCoachingStatistics?DriverId=${driverId}&year=${dateObject.year}&month=${dateObject.month}&day=${dateObject.day}`
    )
    .set("Authorization", `Bearer ${token}`)
    .retry(1, (err, res) => {
      if (res.unauthorized) {
        console.log(`401 error, retry from driver stats APIs, err is ${err}`);
        dispatch({
          type: "ACQUIRE_TOKEN_FAILURE",
          payload: null,
        });
      }
    })
    .then((response) => {
      dispatch(driverStatsHistoryFetched(response.body));
      if (date === "initialLoad") {
        dispatch(driverStatsDateResetted()); // reset the stats history date range if we switched to a new driver
      }
    })
    .catch(console.error);
};
