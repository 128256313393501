import request from "superagent";
import { authProvider } from "../../../../authProvider";
import { baseURL } from "../../../../services/constants";
import { getAccessToken } from "../../../../services/commonFunctions";

let accounts;

// fetching a single driver's scores of the latest week
export const SCORES_FETCHED = "SCORES_FETCHED";

const scoresFetched = (scores) => ({
  type: SCORES_FETCHED,
  payload: scores,
});

// resetting the score already loaded
export const SCORES_RESETTED = "SCORES_RESETTED";

export const scoresReset = () => ({
  type: SCORES_RESETTED,
});

export const loadScores = (driverId) => async (dispatch, getState) => {
  const store = getState();
  var { auth } = store;
  const { driverInManagement } = store;
  const { undoableScores } = store;
  let token = auth?.accessToken;

  // Check if scores and a driver is already loaded
  if (driverInManagement && undoableScores.present) {
    // Check if the request is for the same driver
    if (driverInManagement.driverId === driverId && undoableScores.present.length > 0) {
      const today = new Date();
      const thisWeekIndex = getWeek(today);
      const historyWeekIndex = getWeek(
        new Date(undoableScores.present[0].start)
      );
      // If scores are from previous week (correct), don't send the request again
      if (historyWeekIndex === thisWeekIndex - 1) {
        return;
      } else {
        // Delete scores if the present scores are not from previous week & continue with the request
        dispatch(scoresReset());
      }
    } else {
      // Don't send the request if the same driver is already loaded with no scores from before
      return;
    }
  }

  // Get accessToken
  if (auth != null) {
    if (auth.accessToken == null) {
      try {
        accounts = authProvider.getAllAccounts();
        token = await getAccessToken(accounts, authProvider);
        auth = getState().auth;
      } catch (e) {
        console.log(e);
      }
    }
  }

  if (auth.accessToken) {
    request
      .get(
        `${baseURL}/portal/management/Scores/GetScoresForDriver?driverId=${driverId}&weekspan=1`
      )
      .set("Authorization", `Bearer ${token}`)
      .retry(1, (err, res) => {
        if (res.unauthorized) {
          console.log(`401 error, retry from scores API, err is ${err}`);
          dispatch({
            type: "ACQUIRE_TOKEN_FAILURE",
            payload: null,
          });
        }
      })
      .then((response) => {
        dispatch(scoresFetched(response.body));
      })
      .catch(console.error);
  }
};

const getWeek = (date) => {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  let weekOne = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to weekOne.
  return (
    1 +
    Math.round(
      ((date.getTime() - weekOne.getTime()) / 86400000 -
        3 +
        ((weekOne.getDay() + 6) % 7)) /
        7
    )
  );
};
